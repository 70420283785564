import React, { useState, Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { Link } from 'react-router-dom';

const useStyles = () => {
    return {
        jobContainer: 'bg-white p-4 rounded-lg shadow-md my-4 w-full md:w-3/4 lg:w-1/2 xl:w-1/3 flex flex-col justify-between',
        jobTitle: 'text-xl font-bold mb-2 text-gray-800',
        department: 'text-gray-600 mb-4',
        subtaskContainer: 'relative inline-block text-left',
        subtaskList: 'absolute left-0 z-10 mt-2 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none',
        subtaskItem: 'block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100', // Styles for subtask items
        applyButton: 'bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded mt-5 md:mt-0',
        card: 'bg-white rounded-lg overflow-hidden shadow-md',
        cardContent: 'p-4',
    };
};

const Job = ({ jId, title, description, department, subtasks, instructor }) => {
    const classes = useStyles();
    const [showSubtasks, setShowSubtasks] = useState(false);

    const handleToggleSubtasks = () => {
        setShowSubtasks(!showSubtasks);
    };

    function classNames(...classes) {
        return classes.filter(Boolean).join(' ');
    }

    return (
        <div className={classes.card}>
            <div className={classes.cardContent}>
                <div className="text-xl font-bold mb-2 text-gray-800">{title}</div>
                <div className="text-gray-600 mb-4">{department}</div>
                <Menu as="div" className={classes.subtaskContainer}>
                    <div>
                        <Menu.Button onClick={handleToggleSubtasks} className="flex items-center">
                            {/* <ArrowDropDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" /> */}
                            Subtasks⬇️
                        </Menu.Button>
                    </div>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Menu.Items className={classes.subtaskList}>
                            <div className="py-1">
                                {showSubtasks &&
                                    subtasks.map((subtask, index) => (
                                        <Menu.Item key={index}>
                                            {({ active }) => (
                                                <span
                                                    className={classNames(
                                                        active ? 'bg-gray-100' : 'bg-gray-100 text-gray-700',
                                                        'block px-4 py-2 text-sm hover:bg-gray-100'
                                                    )}
                                                >
                                                    {subtask.name}<hr />
                                                </span>
                                            )}
                                        </Menu.Item>
                                    ))}
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>


            </div>
            <div className="flex justify-center mb-2">
                <Link className={classes.applyButton} to={`/job/${jId}`}>
                    View Details
                </Link>
            </div>
        </div>
    );
};

export default Job;
