import React from "react";
import Card from 'react-bootstrap/Card';
import './statement.css';
const Statement = () => {
  return (
    <div className="statementhead">

      <div className="message-container">
        <h3 className="statementhead"><strong>From our management</strong></h3>
        <div className="message-body">
          <blockquote className="blockquote">
            <p>
              In this era of rapid technological advancements, we're committed to leading change, pushing boundaries, exploring new opportunities, and adapting to emerging trends to reach greater heights.
            </p>
            <footer className="blockquote-footer">
              <cite title="Source Title">Shamim Ahmed</cite>
            </footer>
          </blockquote>
        </div>
      </div>

    </div>
  );
}
export default Statement