import React, { useState } from 'react';
import { collection, addDoc } from 'firebase/firestore';
import { auth, db, storage } from '../firebase'


const AddJob = () => {
    const [isLoading, setIsloading] = useState(false);
    const [jobData, setJobData] = useState({
        name: '',
        description: '',
        department: '',
        subtasks: [{ name: '', description: '' }],
    });

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        const subtasks = [...jobData.subtasks];
        subtasks[index][name] = value;
        setJobData({ ...jobData, subtasks });
    };

    const addSubtask = () => {
        setJobData({ ...jobData, subtasks: [...jobData.subtasks, { name: '', description: '' }] });
    };

    const removeSubtask = (index) => {
        const subtasks = [...jobData.subtasks];
        subtasks.splice(index, 1);
        setJobData({ ...jobData, subtasks });
    };

    const handleSubmit = async (e) => {
        setIsloading(true);
        e.preventDefault();
        try {
            // Add jobData to the "Jobs" collection
            const docRef = await addDoc(collection(db, 'Jobs'), jobData);
            console.log('Job added with ID: ', docRef.id);
            // Reset jobData to clear the form fields after submission
            setJobData({
                name: '',
                description: '',
                department: '',
                subtasks: [{ name: '', description: '' }],
            });
        } catch (error) {
            console.error('Error adding job: ', error);
        }
    };

    return (
        <div className="bg-gradient-to-r from-sky-200 to-purple-300 min-h-screen flex justify-center items-center">
            <div className="max-w-lg w-full bg-white p-8 rounded-lg shadow-lg mt-10 mb-10">
                <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">Add Job</h1>
                <form onSubmit={handleSubmit}>
                    <div className="mb-4">
                        <input required type="text" id="name" name="name" value={jobData.name} onChange={(e) => setJobData({ ...jobData, name: e.target.value })} placeholder="Job Title" className="p-3 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-600" />
                    </div>
                    <div className="mb-4">
                        <textarea required id="description" name="description" value={jobData.description} onChange={(e) => setJobData({ ...jobData, description: e.target.value })} rows="4" placeholder="Job Description" className="p-3 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-600"></textarea>
                    </div>
                    <div className="mb-4">
                        <input required type="text" id="department" name="department" value={jobData.department} onChange={(e) => setJobData({ ...jobData, department: e.target.value })} placeholder="Department" className="p-3 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-600" />
                    </div>
                    <div className="mb-4">
                        <h2 className="text-xl font-semibold text-gray-800 mb-2">Subtasks</h2>
                        {jobData.subtasks.map((subtask, index) => (
                            <div key={index} className="grid grid-cols-3 gap-4 mb-2">
                                <input type="text" name="name" value={subtask.name} onChange={(e) => handleChange(e, index)} placeholder="Subtask Name" className="p-3 col-span-2 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-600" />
                                {index === jobData.subtasks.length - 1 && <button type="button" onClick={addSubtask} className="col-span-1 py-3 px-4 border border-transparent rounded-md shadow-sm text-white bg-sky-500 hover:bg-sky-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-600">+</button>}
                                {index !== jobData.subtasks.length - 1 && <button type="button" onClick={() => removeSubtask(index)} className="col-span-1 py-3 px-4 border border-transparent rounded-md shadow-sm text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-600">-</button>}
                                <textarea name="description" value={subtask.description} onChange={(e) => handleChange(e, index)} rows="2" placeholder="Subtask Description" className="col-span-3 p-3 w-full border rounded-md focus:outline-none focus:ring-2 focus:ring-sky-600"></textarea>
                            </div>
                        ))}
                    </div>
                    <div>
                        {isLoading ? <>
                            <button disabled class="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none justify-self-center whitespace-nowrap bg-cyan-500 hover:bg-cyan-600 focus:bg-cyan-700 disabled:cursor-not-allowed disabled:border-cyan-300 disabled:bg-cyan-300 disabled:shadow-none">
                                <span>Add Job</span>
                                <span class="relative only:-mx-6">
                                    <svg class="w-5 h-5 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" role="graphics-symbol" aria-labelledby="title-05 desc-05">
                                        <title id="title-05">Icon title</title>
                                        <desc id="desc-05">
                                            A more detailed description of the icon
                                        </desc>
                                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                    </svg>
                                </span>
                            </button>

                        </> :
                            <>
                                <button class="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded shadow-lg focus-visible:outline-none whitespace-nowrap bg-cyan-500 shadow-cyan-200 hover:bg-cyan-600 hover:shadow-md hover:shadow-cyan-200 focus:bg-cyan-700 focus:shadow-md focus:shadow-cyan-200 disabled:cursor-not-allowed disabled:border-cyan-300 disabled:bg-cyan-300 disabled:shadow-none">
                                    <span>Add Job</span>
                                </button>


                            </>}

                    </div>
                </form>
            </div>
        </div>
    );
};

export default AddJob;
