import { useEffect, useState } from 'react'
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import logo from '../../image/Logo1.jpg'
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import { Link, useHistory } from 'react-router-dom'
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { Grid, Paper, Typography } from "@mui/material";
import LogoutIcon from '@mui/icons-material/Logout';
import { auth, db, storage } from '../firebase'
import { doc, getDocs, updateDoc, collection, deleteDoc, query, where } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import FullLoader from '../Loader/FullLoader';

const Header = () => {


  const [user_email, setUserEmail] = useState(sessionStorage.getItem("user_email"));
  const [user_name, setUserName] = useState(sessionStorage.getItem("user_name"));
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    const storedEmail = sessionStorage.getItem("user_email");
    const storedName = sessionStorage.getItem("user_name");

    if (storedEmail && storedName) {
      setUserEmail(storedEmail);
      setUserName(storedName);
    }
  }, []);



  const history = useHistory();

  const handleLogout = () => {
    sessionStorage.removeItem("user_name");
    sessionStorage.removeItem("user_email");
    history.push('/login');
    window.location.reload();
  };

  const [admin, setAdmin] = useState(false);
  const checkAdmin = async () => {
    setLoading(true);
    const userRef = collection(db, "users");
    const userDoc = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
    const userData = await getDocs(userDoc);
    if (userData.empty) {
      return;
    }
    userData.forEach((doc) => {
      const userData = doc.data();
      if (userData.isAdmin) {
        setAdmin(true);
      }
    });
    setLoading(false);
  }

  const [instructor, setInstructor] = useState(false);
  const checkInstructor = async () => {
    setLoading(true);
    const userRef = collection(db, "users");
    const userDoc = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
    const userData = await getDocs(userDoc);
    if (userData.empty) {
      history.push('/');
      return;
    }
    userData.forEach((doc) => {
      const userData = doc.data();
      if (!userData.isInstructor) {
        history.push('/');
      } else {
        setInstructor(true);
      }
    });
    setLoading(false);
  }

  useEffect(() => {
    if (user_email != null) {
      checkAdmin();
      checkEmployee();
      checkInstructor();
    }
  }, []);

  const [loading, setLoading] = useState(false);
  const [employee, setEmployee] = useState(false);
  const checkEmployee = async () => {
    setLoading(true);
    const userRef = collection(db, "users");
    const userDoc = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
    const userData = await getDocs(userDoc);
    if (userData.empty) {
      toast.error("Invalid User");
      setLoading(false);
      return;
    }
    userData.forEach((doc) => {
      const userData = doc.data();
      setEmployee(userData.employee);
      setLoading(false);
    });
  }
  return (
    <div>
      {loading ? (
        <></>
      ) : (
        <>
        </>
      )}
      <Navbar bg="light" data-bs-theme="light">
        <Container>
          <Link to="/">
            <Navbar.Brand href="#home">
              <img
                src={logo}
                width="100"
                height="80"
                className="d-inline-block align-top"
                alt="Techavens Logo"
              />
            </Navbar.Brand>
          </Link>
          <Nav className="me-auto">
          </Nav>
          {user_email === null ? (

            <Link to="/login">
              <Button variant="contained">Community Login</Button>
            </Link>

          ) : (
            <div className="d-flex">
              <div>
                <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                  {/* {employee && (
                    <Link to="/metric">
                      <Button variant="contained" style={{ marginRight: "5px" }}>
                        TH Metric
                      </Button>
                    </Link>
                  )} */}
                  {instructor && (
                    loading ? <FullLoader /> : (
                      <>
                        <Link to="/instructor" >
                          <button style={{ marginRight: "1em", marginTop: "1em", marginBottom: "1em" }} class="inline-flex items-center justify-center h-8 gap-2 px-4 text-xs font-medium tracking-wide text-white transition duration-300 rounded shadow-md focus-visible:outline-none justify-self-center whitespace-nowrap bg-cyan-500 shadow-cyan-200 hover:bg-cyan-600 hover:shadow-sm hover:shadow-cyan-200 focus:bg-cyan-700 focus:shadow-sm focus:shadow-cyan-200 disabled:cursor-not-allowed disabled:border-cyan-300 disabled:bg-cyan-300 disabled:shadow-none">
                            <span>Instructor Panel</span>
                          </button>
                        </Link>
                      </>
                    )

                  )}
                  {admin &&
                    loading ? <FullLoader /> :
                    <>

                      <Link to="/admin" >
                        <button style={{ marginRight: "1em", marginTop: "1em", marginBottom: "1em" }} class="inline-flex items-center justify-center h-8 gap-2 px-4 text-xs font-medium tracking-wide text-white transition duration-300 rounded shadow-md focus-visible:outline-none justify-self-center whitespace-nowrap bg-cyan-500 shadow-cyan-200 hover:bg-cyan-600 hover:shadow-sm hover:shadow-cyan-200 focus:bg-cyan-700 focus:shadow-sm focus:shadow-cyan-200 disabled:cursor-not-allowed disabled:border-cyan-300 disabled:bg-cyan-300 disabled:shadow-none">
                          <span>Admin Panel</span>
                        </button>
                      </Link>

                    </>
                  }
                  <div className="flex items-center justify-center">
                    <Button
                      aria-controls={open ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={open ? 'true' : undefined}
                      onClick={handleClick}
                    // variant="contained"
                    >
                      Details
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5" />
                      </svg>
                    </Button>
                  </div>

                </div>

                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                  <Link to="/profile"><MenuItem onClick={handleClose}>Profile</MenuItem></Link>
                  <Button onClick={handleLogout}>
                    <LogoutIcon />Logout
                  </Button>
                </Menu>
              </div>
            </div>
          )}
        </Container>
      </Navbar>

    </div >

  )
};

export default Header