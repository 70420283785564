import React, { useState, useEffect } from 'react';
import { db } from '../firebase';
import { format, isSameDay, isSameMonth } from 'date-fns';
import { Link, useHistory } from 'react-router-dom';
import {
    query,
    collection,
    onSnapshot,
    updateDoc,
    doc,
    addDoc,
    deleteDoc,
    where,
    getDocs,
    orderBy
} from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import { Container, Grid, Paper, Typography, Button } from "@mui/material";
import Loader from '../Loader/Loader.jsx';

const ShowAllTodo = () => {
    const history = useHistory();
    const [completedTodos, setCompletedTodos] = useState([]);


    useEffect(() => {
        const fetchCompletedTodos = async () => {
            const currentDate = new Date();

            // Get the first day of the current month
            const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
            const q = query(
                collection(db, 'todos'),
                where('user', '==', sessionStorage.user_id),
                where('completed', '==', true),
                // orderBy('initiateDate', 'desc')
            );

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                const todosArr = [];
                querySnapshot.forEach((doc) => {
                    const todoData = { ...doc.data(), id: doc.id };
                    const todoDate = todoData.startTime.toDate();
                    if (format(todoDate, 'yyyy-MM') === format(new Date(), 'yyyy-MM')) {
                        todosArr.push(todoData);
                    }
                });
                setCompletedTodos(todosArr);
                setLoading(false);
            });

            return () => unsubscribe();
        };

        fetchCompletedTodos();
    }, []);

    const calculateTotals = () => {
        const dailyTotals = {};

        // Create an empty object to store monthly totals
        const monthlyTotals = {};

        // Initialize a variable to store the total duration in seconds
        let totalDuration = 0;

        // Get the current month in 'yyyy-MM' format
        const currentMonth = format(new Date(), 'yyyy-MM');

        // Iterate over each completed todo
        completedTodos.forEach((todo) => {
            // Get the date and duration of the todo
            const date = todo.startTime.toDate();
            const durationInSeconds = calculateDurationInSeconds(todo.startTime.toDate(), todo.endTime.toDate());

            const monthKey = format(date, 'yyyy-MM');
            monthlyTotals[monthKey] = (monthlyTotals[monthKey] || 0) + durationInSeconds;
            // Check if the todo is from the current month
            if (format(date, 'yyyy-MM') === currentMonth) {
                // Calculate daily total
                const dayKey = format(date, 'yyyy-MM-dd');
                dailyTotals[dayKey] = (dailyTotals[dayKey] || 0) + durationInSeconds;

                // Accumulate total duration
                totalDuration += durationInSeconds;
            }
        });

        return { dailyTotals, monthlyTotals, totalDuration };
    };

    let discordTotal = 0;
    const calculateDurationInSeconds = (startDate, endDate) => {
        const elapsedMilliseconds = endDate - startDate;
        return Math.floor(elapsedMilliseconds / 1000);
    };

    const formatDuration = (durationInSeconds) => {
        const hours = Math.floor(durationInSeconds / 3600);
        const minutes = Math.floor((durationInSeconds % 3600) / 60);
        const seconds = durationInSeconds % 60;

        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const { dailyTotals, monthlyTotals, totalDuration } = calculateTotals();
    const [voiceLogs, setVoiceLogs] = useState(null);

    const [commitmentHour, setCommitmentHour] = useState(0);
    const monthNum = new Date().getMonth() + 1;
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August",
        "September", "October", "November", "December"];
    const [loading, setLoading] = useState(false);
    const [userDetails, setUserDetails] = useState();


    // useEffect hook to fetch user details and voice logs when component mounts
    useEffect(() => {
        const fetchData = async () => {
            setLoading(true); // Set loading to true before fetching data
            try {
                const userRef = collection(db, "users"); // Reference to 'users' collection in Firestore
                const userDoc = query(userRef, where("Email", "==", sessionStorage.getItem("user_email"))); // Query to get user document based on email
                const userData = await getDocs(userDoc); // Fetch user document data

                if (userData.empty) { // If no user data found
                    toast.error("Invalid User"); // Display error toast message
                    setLoading(false); // Set loading to false
                    return; // Exit the function
                }

                userData.forEach((doc) => { // Iterate through user data
                    const userData = doc.data(); // Get user data
                    setUserDetails(userData); // Set user details state
                    const monthNum = new Date().getMonth() + 1; // Get current month number
                    setCommitmentHour(userData.commitmentHours[monthNum]); // Set commitment hour based on current month
                    fetchDiscordData(userData.discordUserId); // Fetch Discord data passing Discord user ID
                });
            } catch (error) { // Catch any errors
                console.error("Error fetching data:", error); // Log the error
                toast.error("Error fetching data"); // Display error toast message
                setLoading(false); // Set loading to false
            }
        };

        fetchData();
    }, []);

    const fetchDiscordData = async (discordUserId) => {
        setLoading(true);
        if (!discordUserId) {
            setLoading(false);
            return;
        }

        try {
            const userRef = collection(db, "voiceLogs");
            const userDoc = query(
                userRef,
                where("userId", "==", discordUserId),
            );

            const userData = await getDocs(userDoc);

            if (userData.empty) {
                toast.error("No voice logs available");
                setLoading(false);
                return;
            }

            let logs = [];
            let discordtotalDuration = 0;
            userData.forEach((doc) => {
                const data = doc.data();
                let joinTime = null;
                let leaveTime = null;

                if (data.joinTime) {
                    joinTime = data.joinTime.toDate();
                }

                if (data.leaveTime) {
                    leaveTime = data.leaveTime.toDate();
                }
                const currentDate = new Date();
                const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

                if (joinTime != null && leaveTime != null) {
                    if (joinTime >= startOfMonth && joinTime <= endOfMonth) {
                        logs.push(data);
                        discordtotalDuration += calculateDurationInSeconds(data.joinTime.toDate(), data.leaveTime.toDate())
                    }
                }
            });
            logs.sort((a, b) => a.joinTime.toDate() - b.joinTime.toDate());
            setVoiceLogs(logs);
            setLoading(false);
            setDiscordTotalDuration(discordtotalDuration);
        } catch (error) {
            console.error("Error fetching Discord data:", error);
            toast.error("Error fetching Discord data");
            setLoading(false);
        }
    };

    const [discordTotalDuration, setDiscordTotalDuration] = useState(0);


    return (<>
        {loading ? <Loader /> : (<>
            <div>
                <Container style={{ marginTop: "10px" }}>
                    <Paper elevation={3} className="p-4">
                        <Link to="/metric">
                            <button className='bg-white hover:bg-blue-500 text-blue-700 font-semibold hover:bg-blue-400 py-2 px-4 border border-blue-500 hover:border-transparent rounded' style={{ marginTop: "10px" }}>
                                TH METRIC</button>
                        </Link>
                        <Link to="/thScheduler">
                            <button className='bg-white hover:bg-blue-500 text-blue-700 font-semibold hover:bg-blue-400 py-2 px-4 border border-blue-500 hover:border-transparent rounded' style={{ marginTop: "10px", marginLeft: "10px" }}>
                                TH Scheduler
                            </button>
                        </Link>
                    </Paper>
                </Container>
                <h3 className="text-3xl font-bold text-center text-gray-800 p-2">Completed Tasks</h3>
                <div className="overflow-x-auto mt-6">
                    {completedTodos.length === 0 ?
                        <>
                            <div>No todo found</div>
                        </> : (
                            <table className="table-auto w-full">
                                <thead>
                                    <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                        <th className="py-3 px-6 text-left">Task Name</th>
                                        <th className="py-3 px-6 text-left">Start Time</th>
                                        <th className="py-3 px-6 text-left">End Time</th>
                                        <th className="py-3 px-6 text-left">Duration (HH:MM:SS)</th>
                                    </tr>
                                </thead>
                                <tbody className="text-gray-600 text-sm font-light">
                                    {completedTodos.map((todo, index) => (
                                        <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                                            <td className="py-3 px-6 text-left">{todo.text}</td>
                                            <td className="py-3 px-6 text-left">{format(todo.startTime.toDate(), 'yyyy-MM-dd HH:mm:ss')}</td>
                                            <td className="py-3 px-6 text-left">{format(todo.endTime.toDate(), 'yyyy-MM-dd HH:mm:ss')}</td>
                                            <td className="py-3 px-6 text-left">{formatDuration(calculateDurationInSeconds(todo.startTime.toDate(), todo.endTime.toDate()))}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        )}

                </div>
                <hr />

                <h3 className="text-3xl font-bold text-center text-gray-800 p-2">Discord Activity</h3>
                {!userDetails || !userDetails.discordUserId ? (
                    <div className="bg-white p-2 rounded">
                        Please Visit {" "}
                        <Link to="/profile" className="text-blue-500 hover:underline">
                            Your Profile
                        </Link>
                        {" "}To Update Discord User Id
                    </div>
                ) : (
                    <>
                        {Array.isArray(voiceLogs) && voiceLogs.length > 0 ? (
                            <div className="overflow-x-auto mt-6">
                                <table className="table-auto w-full">
                                    <thead>
                                        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                            <th className="py-3 px-6 text-left">Channel Name</th>
                                            <th className="py-3 px-6 text-left">Joining Time</th>
                                            <th className="py-3 px-6 text-left">Leaving Time</th>
                                            <th className="py-3 px-6 text-left">Duration (HH:MM:SS)</th>
                                        </tr>
                                    </thead>
                                    <tbody className="text-gray-600 text-sm font-light">
                                        {voiceLogs.map((log, index) => (
                                            <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                                                <td className="py-3 px-6 text-left">{log.channelName}</td>
                                                <td className="py-3 px-6 text-left">{format(log.joinTime.toDate(), 'yyyy-MM-dd HH:mm:ss')}</td>
                                                <td className="py-3 px-6 text-left">{format(log.leaveTime.toDate(), 'yyyy-MM-dd HH:mm:ss')}</td>
                                                <td className="py-3 px-6 text-left">{formatDuration(calculateDurationInSeconds(log.joinTime.toDate(), log.leaveTime.toDate()))}</td>
                                            </tr>
                                        ))}
                                        {/* <td className="py-3 px-6 text-left"></td> */}
                                        {/* <td className="py-3 px-6 text-left"></td> */}
                                        {/* <td className="py-3 px-6 text-left"></td> */}
                                        <td className="py-3 px-6 text-center">
                                            <p className="alert alert-success">Total Duration (HH:MM:SS): {formatDuration(discordTotalDuration)}</p>
                                        </td>
                                    </tbody>


                                </table>
                            </div>
                        ) : (
                            <div>No voice logs available</div>
                        )}
                    </>
                )}



                <hr />
                {/* Daily totals table */}
                <h3 className="text-3xl font-bold text-center text-gray-800 p-2">Daily Totals</h3>
                <table className="table-auto w-full">
                    <thead>
                        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 px-6 text-left">Date</th>
                            <th className="py-3 px-6 text-left">Total Duration</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">
                        {Object.entries(dailyTotals).map(([date, totalDuration], index) => (
                            <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                                <td className="py-3 px-6 text-left">{date}</td>
                                <td className="py-3 px-6 text-left">{formatDuration(totalDuration)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <hr />

                {/* Monthly totals table */}
                <h3 className="text-3xl font-bold text-center text-gray-800 p-2">Monthly Totals</h3>
                <h5 className="mb-2 text-center">Commitment for {monthNames[monthNum - 1]} is: <b>{commitmentHour} Hours</b></h5>
                <table className="table-auto w-full">
                    <thead>
                        <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                            <th className="py-3 px-6 text-left">Month</th>
                            <th className="py-3 px-6 text-left">Total Duration</th>
                        </tr>
                    </thead>
                    <tbody className="text-gray-600 text-sm font-light">
                        {Object.entries(monthlyTotals).map(([month, totalDuration], index) => (
                            <tr key={index} className="border-b border-gray-200 hover:bg-gray-100">
                                <td className="py-3 px-6 text-left">{month}</td>
                                <td className="py-3 px-6 text-left">{formatDuration(totalDuration)}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </>)

        }</>

    );
};

export default ShowAllTodo;
