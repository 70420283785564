import React, { useEffect, useState } from 'react';
import { collection, query, where, onSnapshot, getDocs } from 'firebase/firestore';
import { auth, db, storage } from '../firebase'
import { startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth } from 'date-fns';
import { Link, useHistory } from 'react-router-dom';
import Loader from '../Loader/Loader.jsx';

const AdminAlluserMetric = () => {
    const [usersData, setUsersData] = useState([]);
    const [discordTotalDuration, setDiscordTotalDuration] = useState(0);
    const calculateDurationInSeconds = (startDate, endDate) => {
        const elapsedMilliseconds = endDate - startDate;
        return Math.floor(elapsedMilliseconds / 1000);
    };


    useEffect(() => {
        const unsubscribeUsers = onSnapshot(query(collection(db, 'users'), where('employee', '==', true)), (usersSnapshot) => {
            const users = [];

            usersSnapshot.forEach((userDoc) => {
                const userData = { ...userDoc.data(), id: userDoc.id };

                let dailyTime = 0;
                let weeklyTime = 0;
                let monthlyTime = 0;
                let totalDiscord = 0;

                const today = new Date();
                const startOfToday = startOfDay(today);
                const endOfToday = endOfDay(today);

                const startOfThisMonth = startOfMonth(today);
                startOfThisMonth.setHours(0, 0, 0, 0); // Set to the beginning of the day (midnight)

                const endOfThisMonth = endOfMonth(today);
                endOfThisMonth.setHours(23, 59, 59, 999); // Set to the end of the day (just before midnight)

                const todosQuery = query(collection(db, 'todos'), where('user', '==', userData.UID));
                const unsubscribeTodos = onSnapshot(todosQuery, (todosSnapshot) => {
                    todosSnapshot.forEach((todoDoc) => {
                        const todoData = { ...todoDoc.data(), id: todoDoc.id };

                        if (todoData.completed) {
                            const endTime = todoData.endTime.toDate();
                            const elapsedSeconds = Math.floor((endTime - todoData.startTime.toDate()) / 1000);

                            if (todoData.startTime.toDate() >= startOfToday && todoData.endTime.toDate() <= endOfToday) {
                                dailyTime += elapsedSeconds;
                            }

                            if (todoData.startTime.toDate() >= startOfThisMonth && todoData.endTime.toDate() <= endOfThisMonth) {
                                monthlyTime += elapsedSeconds;
                            }
                        }
                    });

                    // Update user data with time calculations
                    const updatedUserData = { ...userData, dailyElapsedTime: dailyTime, weeklyElapsedTime: weeklyTime, monthlyElapsedTime: monthlyTime, discordTotalDuration: 0 };
                    users.push(updatedUserData);
                    setUsersData(users);
                    setLoading(false);
                });

                // fetchDiscordData(userData.discordUserId)
                //     .then(totalDuration => {
                //         const updatedUserData = { ...userData, discordTotalDuration: totalDuration };
                //         setUsersData(prevUsersData => [...prevUsersData.filter(user => user.id !== updatedUserData.id), updatedUserData]);
                //     })
                //     .catch(error => console.error("Error fetching Discord data:", error));

                // return () => unsubscribeTodos(); // Cleanup on user component unmount
            });

            return () => unsubscribeUsers(); // Cleanup on component unmount
        }, []); // Run once on component mount
    }, []);

    const fetchDiscordData = async (discordUserId) => {
        let totalDuration = 0;

        if (!discordUserId) {
            return totalDuration;
        }

        try {
            const userRef = collection(db, "voiceLogs");
            const userDoc = query(
                userRef,
                where("userId", "==", discordUserId),
            );

            const userData = await getDocs(userDoc);

            if (userData.empty) {
                return totalDuration;
            }

            userData.forEach((doc) => {
                const data = doc.data();
                let joinTime = null;
                let leaveTime = null;

                if (data.joinTime) {
                    joinTime = data.joinTime.toDate();
                }

                if (data.leaveTime) {
                    leaveTime = data.leaveTime.toDate();
                }

                const currentDate = new Date();
                const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
                const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);

                if (joinTime != null && leaveTime != null) {
                    if (joinTime >= startOfMonth && joinTime <= endOfMonth) {
                        totalDuration += calculateDurationInSeconds(joinTime, leaveTime);
                    }
                }
            });

            return totalDuration;
        } catch (error) {
            console.error("Error fetching Discord data:", error);
            return totalDuration;
        }
    };


    const formatTime = (totalSeconds) => {

        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${hours}h ${minutes}m ${seconds}s`;
    };

    const [loading, setLoading] = useState(true);
    return (
        <div>
            {loading ? <Loader /> : (
                <>
                    <h1 style={{ marginTop: "10px" }}>User Matrices</h1>
                    <table className="min-w-full bg-white border border-gray-300">
                        <thead>
                            <tr>
                                <th className="py-2 px-4 border-b">User</th>
                                <th className="py-2 px-4 border-b">Commitment Calender</th>
                                {/* <th className="py-2 px-4 border-b">Discord Elapsed Time</th> */}
                                <th className="py-2 px-4 border-b">Monthly Elapsed Time</th>
                            </tr>
                        </thead>
                        <tbody>
                            {usersData.map((userData) => (
                                <tr key={userData.id}>
                                    <td className="py-2 px-4 border-b">
                                        <Link to={`/individual/metric/admin/thmetric/${userData.UID}`}>{userData.Name}
                                        </Link>
                                    </td>
                                    <Link to={`/individual/admin/thmetric/${userData.UID}`}>
                                        <td className="py-2 px-4 border-b">Link</td>
                                    </Link>
                                    {/* <td className="py-2 px-4 border-b">
                                        {userData.discordUserId ? (
                                            <span>{userData.discordTotalDuration} seconds</span>
                                        ) : (
                                            <span>No Discord User Id Found</span>
                                        )}
                                    </td> */}
                                    <td className="py-2 px-4 border-b">{formatTime(userData.monthlyElapsedTime)} seconds</td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </>
            )
            }

        </div >
    );
};

export default AdminAlluserMetric;
