import React, { useEffect } from 'react';
import { auth, db, storage } from '../firebase'
import { doc, getDocs, updateDoc, collection, deleteDoc, query, where } from 'firebase/firestore';
import { Link, useHistory } from 'react-router-dom';

const AdminHome = () => {
    const history = useHistory();
    const checkAdmin = async () => {
        const userRef = collection(db, "users");
        const userDoc = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
        const userData = await getDocs(userDoc);
        if (userData.empty) {
            history.push('/');
            return;
        }
        userData.forEach((doc) => {
            const userData = doc.data();
            if (!userData.isAdmin) {
                history.push('/');
            }
        });
    }
    useEffect(() => {
        checkAdmin();
    }, []);
    return (
        <div className="bg-gray-800 text-white min-h-screen flex items-center justify-center">
            <div className="text-center">
                <h1 className="text-4xl font-bold mb-6">Admin Dashboard</h1>
                <div className="space-y-4">
                    <Link to="/admin/thuser">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded sm:mr-2" style={{ marginRight: "10px" }}>
                            Manage Users
                        </button>
                    </Link>
                    <Link to="/admin/thmetric">
                        <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                            User Metrics
                        </button>
                    </Link>
                </div>
            </div>
        </div>

    );
};

export default AdminHome;
