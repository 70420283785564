import React, { useEffect, useRef, useState } from 'react';
import { HashRouter  as Router, Route, Switch  } from 'react-router-dom';
import AOS from 'aos';
import 'aos/dist/aos.css';
import Header from './components/Hero/Header';
import Banner from './components/Banner/Banner';
import './App.css';
import Statement from './components/Statements/Statement';
import 'bootstrap/dist/css/bootstrap.min.css';
import Product from './components/Product/Product';
import Footer from './components/Footer/Footer';
import AboutUs from './components/AboutUs/AboutUs';
import Login from './components/Login/Login';
import Register from './components/Register/Register';
import User from './components/Admin/User';
import UserProfile from './components/UserProfile/UserProfile';
import TodoMain from './components/Metric/TodoMain';
import ResetPassword from './components/ResetPassword/ResetPassword';
import AdminHome from './components/Admin/AdminHome';
import AdminAlluserMetric from './components/Admin/AdminAlluserMetric';
import AdminIndividualuserMetric from './components/Admin/AdminIndividualuserMetric';
import AdminCalenderView from './components/Admin/AdminCalenderView';
import SchedulerMain from './components/Scheduler/SchedulerMain';
import ShowAllTodo from './components/Metric/ShowAllTodo';
import NotFound from './components/NotFound/NotFound';
import AllJob from './components/Job/AllJob';
import InstructorJob from './components/Instructor/InstructorJob';
import JobScreen from './components/Job/JobScreen';
import InstructorHome from './components/Instructor/InstructorHome';
import AddJob from './components/Instructor/AddJob';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

function App() {
  useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);

  const productRef = useRef(null);

  const scrollToProduct = () => {
    if (productRef.current) {
      const offset = 100;
      const elementPosition = productRef.current.getBoundingClientRect().top;
      window.scrollBy({ top: elementPosition - offset, behavior: 'smooth' });
    }
  };
  const [showFooter, setShowFooter] = useState(true);
   useEffect(() => {
    AOS.init({ duration: 1000 });
  }, []);
  return (
    <Router basename="/">
      <div className="App">
        <Header />
        <Switch>
          <Route path="/instructor/job" component={InstructorJob} />
          <Route path="/instructor/addJob" component={AddJob} />
          <Route path="/admin/thuser" component={User} />
          <Route path="/admin/thmetric" component={AdminAlluserMetric} />
          <Route path="/instructor" component={InstructorHome} />
          <Route path="/login" component={Login} />
          <Route path="/reset" component={ResetPassword} />
          <Route path="/admin" component={AdminHome} />
          <Route path="/myMetric" component={ShowAllTodo} />
          <Route path="/thScheduler" component={SchedulerMain} />
          <Route path="/register" component={Register} />
          <Route path="/profile" component={UserProfile} />
          <Route path="/metric" component={TodoMain} />
          <Route path="/allJob" component={AllJob} />
          <Route path="/individual/metric/admin/thmetric/:userId" component={AdminIndividualuserMetric} />
          <Route path="/individual/admin/thmetric:userId" component={AdminCalenderView} />
          <Route path="/job/:jobId" component={JobScreen} />

          <Route exact path="/">
            <div onClick={scrollToProduct} style={{ cursor: 'pointer' }}>
              <Banner />
            </div>
            <div className="container">
              <div data-aos="fade-up" data-aos-duration="2000">
                <AboutUs />
              </div>
              <div data-aos="fade-up" data-aos-duration="2000" ref={productRef}>
                <Product />
              </div>
              <div data-aos="fade-up" data-aos-duration="1000">
                <Statement />
              </div>
            </div>
          </Route>
      <Route path="*" component={NotFound} />
        </Switch>
        {showFooter && (
          <div data-aos="fade-up" data-aos-duration="1000">
            <Footer />
          </div>
        )}
      </div>
  
    </Router>
    
  );
}

export default App;

