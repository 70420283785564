import React, { useState, useEffect } from "react";
import { ToastContainer, toast } from 'react-toastify';
import { Container, Grid, Paper, Typography, Button } from "@mui/material";
import Loader from '../Loader/Loader.jsx';
import { auth, db, storage } from '../firebase'
import { doc, getDocs, updateDoc, collection, deleteDoc, query, where } from 'firebase/firestore';
import { Link, useHistory } from 'react-router-dom'

const UserProfile = () => {
    const [approveStatus, setApproveStatus] = useState(false);
    const [loading, setLoading] = useState(true);
    const [userDetails, setUserDetails] = useState({
        name: "",
        email: "",
        phone: "",
    });
    const history = useHistory();
    const [employee, setEmployee] = useState(false);
    const [commitmentHours, setCommitmentHours] = useState(0);
    const [discordUID, setDiscordUID] = useState(null);

    useEffect(() => {
        if (!sessionStorage.getItem("user_email") !== "" && sessionStorage.getItem("user_email") == null) {
            history.push('/');
        }
    }, []);

    useEffect(() => {
        checkData();
    }, []);

    const checkData = async () => {
        try {
            setLoading(true);
            await checkApproved();
            await checkEmployee();
            await fetchData();
        } catch (error) {
            console.error("Error fetching data:", error);

        } finally {
            setLoading(false);
        }
    };

    const checkApproved = async () => {
        const userRef = collection(db, "users");
        const userDoc = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
        const userData = await getDocs(userDoc);

        if (userData.empty) {
            toast.error("Invalid User");
            return;
        }

        userData.forEach((doc) => {
            const userData = doc.data();
            setApproveStatus(userData.approved);
            setUserDetails({
                name: userData.Name,
                email: userData.Email,
                phone: userData.Phone,
                discord: userData.discordUserId
            });
        });
    }

    const checkEmployee = async () => {
        const userRef = collection(db, "users");
        const userDoc = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
        const userData = await getDocs(userDoc);

        if (userData.empty) {
            toast.error("Invalid User");
            return;
        }

        userData.forEach((doc) => {
            const userData = doc.data();
            setEmployee(userData.employee);
        });
    }

    const fetchData = async () => {
        try {
            setLoading(true);
            const userRef = collection(db, "users");
            const userDoc = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
            const userData = await getDocs(userDoc);

            if (userData.empty) {
                toast.error("Invalid User");
                setLoading(false);
                return;
            }

            userData.forEach((doc) => {
                const userData = doc.data();
                setCommitmentHours(userData.commitmentHours[new Date().getMonth() + 1]);
            });

            setLoading(false);
        } catch (error) {
            console.error("Error fetching data:", error);
            setLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const userId = sessionStorage.getItem("user_id");

        if (!userId) {
            console.error("User ID not found in sessionStorage");
            setLoading(false);
            return;
        }

        const userRef = collection(db, "users");
        const userQuery = query(userRef, where("UID", "==", userId));
        const querySnapshot = await getDocs(userQuery);

        if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0].ref;

            await updateDoc(userDoc, {
                discordUserId: discordUID
            });
        } else {
            console.error("Document with UID does not exist:", userId);
        }

        // fetchData();
        // setLoading(false);
        window.location.reload();
    };

    return (
        <div>
            {loading ? (<Loader />) : (
                <Container>
                    {loading && <Loader />}
                    <ToastContainer />
                    <Grid container spacing={2} style={{ padding: "16px" }}>
                        <Grid item xs={12}>
                            <Paper>
                                {approveStatus && (
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} md={6}>
                                            <Typography variant="h6" align="center" style={{ padding: "16px" }}>
                                                User Profile
                                            </Typography>
                                            <Typography variant="body1" style={{ padding: "16px" }}>
                                                Name: {userDetails.name}
                                            </Typography>
                                            <Typography variant="body1" style={{ padding: "16px" }}>
                                                Email: {userDetails.email}
                                            </Typography>
                                            <Typography variant="body1" style={{ padding: "16px" }}>
                                                Phone: {userDetails.phone}
                                            </Typography>
                                            {!userDetails.discord ? (
                                                <div className="flex items-center justify-center">
                                                    <form onSubmit={handleSubmit} className="flex bg-gray-100 p-4 rounded-md">
                                                        <div className="">
                                                            <label className="mr-2">Enter Discord Id:</label>
                                                            <input
                                                                required
                                                                type="text"
                                                                onChange={(e) => setDiscordUID(e.target.value)}
                                                                className="border rounded p-2"
                                                            />
                                                        </div>
                                                        <button type="submit" className="bg-blue-500 text-white p-2 rounded" style={{ maxHeight: "50px", marginLeft: "5px" }}>Submit</button>
                                                    </form>
                                                </div>
                                            ) : (
                                                <>
                                                    <Typography variant="body1" style={{ padding: "16px" }}>
                                                        Discord User Id: {userDetails.discord}
                                                    </Typography>
                                                </>
                                            )}
                                            <Button variant="outlined" align="center" style={{ padding: "16px", marginBottom: '10px' }}>
                                                <Link to="/mymetric">My Metric</Link>
                                            </Button>
                                        </Grid>
                                        <Grid item xs={12} md={6}>
                                            <div style={{ margin: '10px 10px' }}>
                                                {employee && (
                                                    <>
                                                        <Button variant="outlined" align="center" style={{ padding: "16px" }}>
                                                            <Link to="/thScheduler">TH Scheduler</Link>
                                                        </Button>
                                                    </>
                                                )}
                                                {employee && commitmentHours > 0 && (
                                                    <>
                                                        <Button variant="outlined" align="center" style={{ padding: "16px", marginLeft: '10px' }}>
                                                            <Link to="/metric">TH Metric</Link>
                                                        </Button>
                                                    </>
                                                )}
                                                <br />
                                                {employee && (
                                                    <>
                                                        <Button variant="outlined" align="center" style={{ padding: "16px", marginTop: '10px' }}>
                                                            <Link to="/allJob">TH Job Portal</Link>
                                                        </Button>
                                                    </>
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                )}
                                {!approveStatus && !loading && (
                                    <Typography
                                        variant="body1"
                                        align="center"
                                        style={{
                                            padding: "16px",
                                            backgroundColor: "#FF5733",
                                            color: "white",
                                            borderRadius: "8px",
                                            marginBottom: "150px",
                                            marginTop: "100px",
                                        }}
                                    >
                                        Approval Required. Please Contact Admin.
                                    </Typography>
                                )}
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
            )}
        </div>
    );
};

export default UserProfile;
