import React, { useState, useEffect } from 'react';
import { Scheduler } from "@aldabil/react-scheduler";
import Loader from '../Loader/Loader.jsx';
import { ToastContainer, toast } from 'react-toastify';
import { auth, db, storage } from '../firebase.js'
import { doc, getDoc, getDocs, updateDoc, collection, deleteDoc, query, where } from 'firebase/firestore';
import ErrorBoundary from '../ErrorBoundary .jsx';

const THScheduler = ({ prevEvents, getEvents }) => {
    const [loading, setLoading] = useState(true);
    let events = [];


    const [validEvents, setValidEvents] = useState();
    const [showToast, setShowToast] = useState(false);

    const checkForEvent = () => {
        if (prevEvents && prevEvents.length > 0) {
            if (events && events.length > 0) {
                events = [...new Set([...prevEvents, ...events])];
            } else {
                events = prevEvents;
            }

        } else {
            events = events;
        }
        return events;
    };

    useEffect(() => {
        if (events.length > 0) {
            checkForClashes();
        }
        getEvents();
        checkForEvent();
    }, [prevEvents, events]);

    const checkForClashes = (newEvent, existingEvents) => {
        if (!newEvent || !newEvent.start || !newEvent.end) {
            // Handle the case where newEvent is not properly defined
            return false;
        }

        const startEvent = newEvent.start;
        const endEvent = newEvent.end;

        return existingEvents.some((existingEvent) => {
            const existingStart = existingEvent.start;
            const existingEnd = existingEvent.end;

            if (!existingStart || !existingEnd) {
                // Handle the case where existingEvent is not properly defined
                return false;
            }

            return startEvent < existingEnd && existingStart < endEvent;
        });
    };


    const handleConfirm = async (event, action) => {
        try {
            const monthCount = new Date().getMonth();
            const startEvent = event.start;
            const endEvent = event.end;

            const userRef = collection(db, "users");
            const userQuery = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
            const userQuerySnapshot = await getDocs(userQuery);


            // Check for clashes with existing events
            const isClash = checkForClashes(event, events);
            if (isClash && action === "create") {
                toast.error("Event clashes with existing events. Please choose a different time.");
                event.color = "#900000"
            }
            if (!(startEvent.getMonth() === monthCount && startEvent.getDate() <= endEvent.getDate())) {
                event.color = "#900000"
                toast.error("Past or Future month Input's are Invalid");

            }
            if (action === "create") {
                const timestamp = new Date().getTime();
                event.event_id = timestamp;
                events.push(event);
                if (!userQuerySnapshot.empty) {
                    const userDoc = userQuerySnapshot.docs[0];
                    const userData = userDoc.data();
                    await updateDoc(doc(db, "users", userDoc.id), { commits: events });
                    checkForEvent();
                } else {
                }
                return event;
            } else if (action === "edit") {
                if (startEvent.getMonth() === monthCount && startEvent.getDate() <= endEvent.getDate()) {
                    events = events.map((prevEvent) =>
                        prevEvent.event_id === event.event_id ? event : prevEvent
                    );
                    if (!userQuerySnapshot.empty) {
                        const userDoc = userQuerySnapshot.docs[0];
                        const userData = userDoc.data();
                        await updateDoc(doc(db, "users", userDoc.id), { commits: events });
                        checkForEvent();
                        setValidEvents(events);
                    } else {
                    }
                } else {
                    // setShowToast(true);
                    events.push(event);
                    checkForEvent();
                    toast.error("Past or Future month Input's are Invalid");
                }
                return event;
            }

        } catch (error) {
            console.error("Error confirming event:", error);
            toast.error("Past or Future month Input's are Invalid");
        }
    };

    const handleDelete = async (id) => {
        try {
            const updatedEvents = events.filter((event) => event.event_id !== id);
            events = updatedEvents;
            const userRef = collection(db, "users");
            const userQuery = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
            const userQuerySnapshot = await getDocs(userQuery);

            if (!userQuerySnapshot.empty) {
                // Assuming there's only one user with the specified email
                const userDoc = userQuerySnapshot.docs[0];
                const userData = userDoc.data();

                // Update the "commits" field with the new value (assuming 'events' is an array)
                await updateDoc(doc(db, "users", userDoc.id), { commits: events });
                setValidEvents(events);

            } else {
                console.log("No user found with the specified email");
            }
            return id;
        } catch (error) {
            console.error("Error deleting event:", error);
            throw error;
        }
    };


    const handleToastClose = () => {
        toast.error("Event clashes with existing events. Please choose a different time.");
    };
    const monthNum = new Date().getMonth() + 1;
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August",
        "September", "October", "November", "December"];


    const [newTotalHour, setNewTotalHour] = useState(0);
    const [newTotalMinute, setNewTotalMinute] = useState(0);


    return (
        <>
            <ErrorBoundary>
                <div>
                    <ToastContainer />
                    <Scheduler
                        view={'month'}
                        // loadingComponent={<Loader />}
                        timeZone={'Asia/Dhaka'}
                        height={500}
                        events={checkForEvent()}
                        onConfirm={handleConfirm}
                        onDelete={handleDelete}
                        disableViewNavigator={true}
                        selectedDate={new Date()}
                        navigation={false}
                        draggable={false}
                    />
                    {showToast && (
                        <div id="toast-warning" className="flex items-center w-full max-w-xs p-4 text-gray-500 bg-white rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 fixed  space-x-4  divide-gray-200 rounded-lg shadow top-5 right-5 dark:divide-gray-700 space-x dark:bg-gray-800" role="alert">
                            <div className="inline-flex items-center justify-center flex-shrink-0 w-8 h-8 text-orange-500 bg-orange-100 rounded-lg dark:bg-orange-700 dark:text-orange-200">
                                <svg className="w-5 h-5" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM10 15a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm1-4a1 1 0 0 1-2 0V6a1 1 0 0 1 2 0v5Z" />
                                </svg>
                                <span className="sr-only">Warning icon</span>
                            </div>
                            <div className="ms-3 font-normal">Past or Future month Input's are Invalid</div>
                            <button type="button" className="ms-auto -mx-1.5 -my-1.5 bg-white text-gray-400 hover:text-gray-900 rounded-lg focus:ring-2 focus:ring-gray-300 p-1.5 hover:bg-gray-100 inline-flex items-center justify-center h-8 w-8 dark:text-gray-500 dark:hover:text-white dark:bg-gray-800 dark:hover:bg-gray-700" onClick={handleToastClose} aria-label="Close">
                                <span className="sr-only">Close</span>
                                <svg className="w-3 h-3" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                                    <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                                </svg>
                            </button>
                        </div>
                    )}
                </div>
            </ErrorBoundary>
        </>
    );
};

export default THScheduler;
