import React from 'react';
import './AboutUs.css'
const AboutUs = () => {
    return (
        <div className='container' style={{ marginTop: '20px' }}>
            <h3 className='about'><strong>About Us</strong></h3>
            <div className="row">
                <div className="col-md-4">
                    <h3 style={{ color: '#61bed6' }}><strong>Who We Are</strong></h3>
                    <p>
                        "Techavens" combines the word "Tech" with "Havens", which implies a place of safety, refuge, or shelter. We as a company provide a safe and secure place for clients to come to fulfill all their technology needs.
                    </p>
                </div>
                <div className="col-md-4">
                    <h3 style={{ color: '#61bed6' }}><strong>Our Mission</strong></h3>
                    <p>
                        To provide innovative and reliable technology solutions that empower businesses and individuals to achieve their goals. We are committed to delivering exceptional customer service and building long-term relationships based on trust, integrity, and mutual respect.
                    </p>
                </div>

                <div className="col-md-4">
                    <h3 style={{ color: '#61bed6' }}><strong>Our Vision</strong></h3>
                    <p>
                        To be the leading provider of innovative and reliable technology solutions that empower our clients to achieve their goals, while fostering a culture of collaboration and growth within our team and community.
                    </p>
                </div>
            </div>
        </div>
    );
};

export default AboutUs;
