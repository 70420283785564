import React, { useState, useEffect } from 'react';
import { Scheduler } from "@aldabil/react-scheduler";
import Loader from '../Loader/Loader.jsx';
import { ToastContainer, toast } from 'react-toastify';
import { auth, db, storage } from '../firebase.js'
import { doc, getDoc, getDocs, updateDoc, collection, deleteDoc, query, where } from 'firebase/firestore';
import ErrorBoundary from '../ErrorBoundary .jsx';
import { Link, useParams } from 'react-router-dom';


const AdminCalenderView = () => {
    const { userId } = useParams();
    const [loading, setLoading] = useState(true);
    const [events, setEvents] = useState(null);

    const [totalHour, setTotalHour] = useState(0);
    const [totalMinute, setTotalMinute] = useState(0);
    const [commitmentHour, setCommitmentHour] = useState(0);
    const monthNum = new Date().getMonth() + 1;
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August",
        "September", "October", "November", "December"];

    const calculateTotalTimeFromArray = async (eventsArray) => {
        if (!eventsArray || eventsArray.length === 0) {
            setTotalHour(0);
            setTotalMinute(0);
            return;
        }
        let totalHour = 0;
        let totalMinute = 0;

        eventsArray.forEach((event) => {
            const startTime = new Date(event.start);
            const endTime = new Date(event.end);

            if (!isNaN(startTime) && !isNaN(endTime) && event.color === '#00FF00') {
                const timeDiffMilliseconds = endTime - startTime;
                const totalTimeInMinutes = timeDiffMilliseconds / (1000 * 60);

                totalHour += Math.floor(totalTimeInMinutes / 60);
                totalMinute += Math.floor(totalTimeInMinutes % 60);
            }
        });

        // Add to the previous values
        setTotalHour(totalHour);
        setTotalMinute(totalMinute);
    };

    const getEvents = async () => {
        try {
            const userRef = collection(db, "users");
            const userQuery = query(userRef, where("UID", "==", userId.toString()));
            const querySnapshot = await getDocs(userQuery);

            if (!querySnapshot.empty) {
                const userData = querySnapshot.docs[0].data();
                if (userData.commits.length <= 0 || !userData.commits) {
                    setEvents([]);
                    setLoading(false);
                    return;
                }
                const formattedCommits = userData.commits.map((commit) => {
                    const startTime = new Date(commit.start.seconds * 1000);
                    const endTime = new Date(commit.end.seconds * 1000);
                    let color = '#00FF00';
                    if (commit.color && commit.color === '#900000') {
                        color = '#900000'
                    }
                    return {
                        event_id: commit.event_id,
                        title: commit.title,
                        start: startTime,
                        end: endTime,
                        color: color
                    };
                });

                setEvents(formattedCommits);
                calculateTotalTimeFromArray(formattedCommits);
            } else {
                toast.error("Invalid User");
                setEvents([]);
                setLoading(false);
            }
        } catch (error) {
            // console.error("Error getting events:", error);
            // toast.error("Error getting events. Please try again.");
            setLoading(false);
        } finally {
            setLoading(false);
        }
    };
    const [userName, setUserName] = useState();
    useEffect(() => {
        const fetchData = async () => {
            try {
                const userRef = collection(db, "users");
                const userDoc = query(userRef, where("UID", "==", userId));
                const userData = await getDocs(userDoc);

                if (userData.empty) {
                    toast.error("Invalid User");
                    setLoading(false);
                    return;
                }

                userData.forEach((doc) => {
                    const userData = doc.data();
                    setUserName(userData.Name);
                    if (userData.commitmentHours[monthNum]) {
                        setCommitmentHour(userData.commitmentHours[monthNum]);
                    }
                });

                setLoading(false);
            } catch (error) {
                // console.error("Error fetching data:", error);
                // toast.error("Error fetching data");
                setLoading(false);
            }
        };

        fetchData();
        getEvents();
    }, [userId]);

    useEffect(() => {
        calculateTotalTimeFromArray(events);
    }, [events]);

    return (
        <>
            <ErrorBoundary>
                {loading ? <Loader /> : (
                    <>
                        <div>
                            <ToastContainer />
                            <div className="mt-4 flex flex-col">
                                <div className="flex flex-wrap justify-center">
                                    <div className="w-full md:w-1/4 p-4">
                                        <Link to="/admin/thmetric" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded inline-flex items-center">
                                            <svg
                                                className="w-6 h-6 mr-2"
                                                fill="none"
                                                stroke="currentColor"
                                                viewBox="0 0 24 24"
                                                xmlns="http://www.w3.org/2000/svg"
                                            >
                                                <path
                                                    strokeLinecap="round"
                                                    strokeLinejoin="round"
                                                    strokeWidth="2"
                                                    d="M11 19l-7-7 7-7m8 14l-7-7 7-7"
                                                ></path>
                                            </svg>
                                            Go Back
                                        </Link>

                                    </div>

                                    {/* Second Column */}
                                    <div className="w-full md:w-1/4 p-4">
                                        <h4>Matrices of <br></br>{userName}</h4>
                                    </div>

                                    {/* Third Column */}
                                    <div className="w-full md:w-1/4 p-4">
                                        <h4 className="mb-2 text-center">Commitment for {monthNames[monthNum - 1]} is: <b>{commitmentHour} Hours</b></h4>
                                    </div>

                                    {/* Fourth Column */}
                                    <div className="w-full md:w-1/4 p-4">
                                        <h4 className="mb-2 text-center">Commitment Booked {monthNames[monthNum - 1]} is: <b>{totalHour} Hours</b> <b>{totalMinute} Minutes</b></h4>
                                    </div>
                                </div>
                            </div>
                            {events !== null ? (
                                <Scheduler
                                    view={'month'}
                                    timeZone={'Asia/Dhaka'}
                                    height={500}
                                    events={events}
                                    disableViewNavigator={true}
                                    selectedDate={new Date()}
                                    navigation={false}
                                    draggable={false}
                                    editable={false}
                                    deletable={false}
                                />
                            ) : (
                                // <Loader />
                                <></>
                            )}
                        </div>
                    </>
                )}

            </ErrorBoundary>
        </>
    );
};

export default AdminCalenderView;
