import { useState, useEffect } from "react";
import { Form, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { auth, db, storage } from '../firebase'
import { addDoc, collection } from 'firebase/firestore';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useHistory } from 'react-router-dom'
import { uploadBytes, uploadBytesResumable, ref, getDownloadURL } from "firebase/storage";
import Loader from '../Loader/Loader.jsx'
import Grid from '@mui/material/Grid';


const Register = () => {
    const [email, setEmail] = useState('');
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [cPassword, setCPassword] = useState('');
    const [phone, setPhone] = useState('');
    const history = useHistory();
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');



    useEffect(() => {
        if (sessionStorage.getItem("user_email") != "" && !sessionStorage.getItem("user_email") == null) {
            history.push('/');
        }
    }, []);

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);
        const file = e.target[5].files[0];
        if (password === cPassword) {
            createUserWithEmailAndPassword(auth, email, password)
                .then(async (userCredential) => {
                    uploadFile(file, userCredential.user.uid);
                    try {
                        const docRef = await addDoc(collection(db, "users"), {
                            UID: `${userCredential.user.uid}`,
                            Name: name,
                            Email: email,
                            Phone: phone,
                            approved: false,
                            employee: false,
                            tasks: [],
                        });
                    } catch (e) {
                        toast.error("Some error happened. Try Again");
                        setLoading(false);
                    }
                    const user = userCredential.user;
                    setName('');
                    setEmail('');
                    setPassword('');
                    setCPassword('');
                    setPhone('');
                    setLoading(false);
                    toast.success("Registered Successfully");
                    history.push('/login');
                })
                .catch((error) => {
                    setName('');
                    setEmail('');
                    setPassword('');
                    setCPassword('');
                    setPhone('');
                    toast.error("Invalid Credentials");
                    console.log(error);
                    setLoading(false);
                });
        } else {
            toast.error("Passoword Doesn't Match");
            setLoading(false);
        }

    };

    const uploadFile = (file, uid) => {
        if (!file) return;
        const storageRef = ref(storage, `files/${uid}`);
        const uploadTask = uploadBytesResumable(storageRef, file);
    }

    return (
        <Grid>
            <Container>
                <ToastContainer />
                <div className="d-flex justify-content-center" style={{ width: "100%" }}>
                    <div>
                        <h1 style={{ marginTop: '20px', color: '#61bed6' }}>Register</h1>
                        <Form onSubmit={submitHandler}>
                            <Form.Group controlId="name" style={{ marginBottom: '20px' }}>

                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Enter Name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group controlId="email" style={{ marginBottom: '20px' }}>

                                <Form.Control
                                    required
                                    type="email"
                                    placeholder="Enter Email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group controlId="password" style={{ marginBottom: '20px' }}>

                                <Form.Control
                                    required
                                    type="password"
                                    placeholder="Enter Password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group controlId="Cpassword" style={{ marginBottom: '20px' }}>

                                <Form.Control
                                    required
                                    type="password"
                                    placeholder="Confirm Password"
                                    value={cPassword}
                                    onChange={(e) => setCPassword(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group controlId="phone" style={{ marginBottom: '20px' }}>

                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Enter Phone NO"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                />
                            </Form.Group>

                            <Form.Group controlId="CV" style={{ marginBottom: '20px' }}>
                                <Form.Label style={{ marginLeft: "-160px", fontWeight: 'bold' }}>Attach CV (Only .pdf)</Form.Label>
                                <Form.Control
                                    required
                                    type="file"
                                    className="input"
                                    accept=".pdf"
                                />
                            </Form.Group>

                            {loading ? <Loader /> : (
                                <Button type="submit" variant='primary' style={{ marginBottom: '20px', textWeight: 'bold' }}>
                                    Register
                                </Button>
                            )}

                            <p style={{ fontSize: "15px", fontWeight: "bold", textTransform: "uppercase", marginTop: '10px' }}>
                                Already a Member?
                                <Link style={{ color: "#61bed6" }} to="/login"> Login</Link>
                            </p>
                        </Form>
                    </div>
                </div>
            </Container>
        </Grid>

    );
};

export default Register;
