import React, { useState, Fragment, useEffect } from 'react';
import { db } from '../firebase';
import { useParams, useHistory } from 'react-router-dom';
import Loader from '../Loader/Loader.jsx';
import { doc, getDocs, getDoc, collection, query, where, updateDoc } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';

const JobScreen = () => {
    const { jobId } = useParams();
    const [jobData, setJobData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [bloading, setbLoading] = useState(false);
    const [jobApproved, setJobApproved] = useState(false);
    const [isInstructor, setisInstructor] = useState(false);
    const [applicants, setApplicants] = useState([]);
    const [applied, setApplied] = useState(false);
    const [abLoading, setabLoading] = useState(false);
    const history = useHistory();

    useEffect(() => {
        if (sessionStorage.getItem("user_email") != "" && !sessionStorage.getItem("user_email") == null) {
            history.push('/');
        }
    }, []);
    useEffect(() => {
        fetchJob();
        checkApplied(jobId);
        fetchApplicants();
    }, []);

    const fetchJob = async () => {
        try {
            const jobDocRef = doc(db, 'Jobs', jobId);
            const jobDocSnap = await getDoc(jobDocRef);
            if (jobDocSnap.exists()) {
                const job = { ...jobDocSnap.data(), id: jobDocSnap.id };
                setJobData(job);
            } else {
                console.log('No such document!');
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching job:', error);
            setLoading(false);
        }
    };
    const fetchApplicants = async () => {
        const usersRef = collection(db, "users");
        const usersSnapshot = await getDocs(usersRef);
        const applicantsData = [];
        usersSnapshot.forEach((doc) => {
            const userData = doc.data();
            if (userData.tasks && userData.tasks.length > 0) {
                const tasks = userData.tasks;
                tasks.forEach((task) => {
                    if (task.id === jobId) {
                        applicantsData.push({
                            id: doc.id,
                            name: userData.Name,
                            task: task
                        });
                    }

                });
            }


        });
        setApplicants(applicantsData);
    };
    const checkApplied = async (job) => {
        setLoading(true);
        try {
            const userRef = collection(db, "users");
            const userQuery = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
            const userSnapshot = await getDocs(userQuery);
            userSnapshot.forEach(async (doc) => {
                try {
                    const userData = doc.data();
                    setisInstructor(userData.isInstructor);
                    let tasks = userData.tasks;
                    if (tasks.length > 0) {
                        const taskExists = tasks.some(task => task.id === job);
                        const task = tasks.find(task => task.id === job);
                        if (taskExists) {
                            setApplied(true);
                            if (task.approved) {
                                setJobApproved(task.approved);
                            }
                        }
                    }
                    setLoading(false);
                } catch (error) {
                    console.error("Error updating tasks field:", error);

                    setLoading(false);
                }
            });
        } catch (error) {
            console.error("Error fetching user document:", error);

            setLoading(false);
        }
    };


    if (loading) {
        return <Loader />;
    }

    const apply = async (id) => {
        setbLoading(true);
        try {
            const userRef = collection(db, "users");
            const userQuery = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
            const userSnapshot = await getDocs(userQuery);

            if (userSnapshot.empty) {
                toast.error("Please Register First");
                setbLoading(false);
                return;
            }

            userSnapshot.forEach(async (doc) => {
                try {
                    const userData = doc.data();
                    let tasks = userData.tasks;
                    if (!tasks || !Array.isArray(tasks)) {
                        tasks = [];
                    }
                    const taskExists = tasks.some(task => task.id === id);
                    if (taskExists) {
                        toast.info("Already applied");
                    }
                    if (!taskExists) {
                        const newTask = {
                            id: id,
                            approved: false,
                            timeStamp: new Date(),
                        };
                        tasks.push(newTask);
                        await updateDoc(doc.ref, { tasks });
                    }
                    setbLoading(false);
                } catch (error) {
                    console.error("Error updating tasks field:", error);
                    setbLoading(false);
                }
            });
        } catch (error) {
            console.error("Error fetching user document:", error);
            setLoading(false);
        }
        fetchJob();
        checkApplied(jobId);
        fetchApplicants();
    };

    const handleApprove = async (userId, taskId) => {
        setabLoading(true);
        try {
            const userRef = doc(db, "users", userId);
            const userSnapshot = await getDoc(userRef);

            if (userSnapshot.exists()) {
                const userData = userSnapshot.data();
                const updatedTasks = userData.tasks.map(task => {
                    if (task.id === taskId) {
                        return { ...task, approved: true };
                    }
                    return task;
                });

                await updateDoc(userRef, { tasks: updatedTasks });
                fetchJob(); // Refresh job data
                fetchApplicants(); // Refresh applicant data
                setabLoading(false);
            } else {
                console.error("User document does not exist.");
                setabLoading(false);
            }
        } catch (error) {
            console.error("Error approving task:", error);
            setabLoading(false);
        }
    };

    const handleDisapprove = async (userId, taskId) => {
        setabLoading(true);
        try {
            const userRef = doc(db, "users", userId);
            const userSnapshot = await getDoc(userRef);

            if (userSnapshot.exists()) {
                const userData = userSnapshot.data();
                const updatedTasks = userData.tasks.map(task => {
                    if (task.id === taskId) {
                        return { ...task, approved: false };
                    }
                    return task;
                });

                await updateDoc(userRef, { tasks: updatedTasks });
                fetchJob();
                fetchApplicants();
                setabLoading(false);
            } else {
                console.error("User document does not exist.");
                setabLoading(false);
            }
        } catch (error) {
            console.error("Error disapproving task:", error);
            setabLoading(false);
        }
    };


    return (
        <>
            <ToastContainer />
            <div className="p-4" style={{ marginTop: "20px" }}>
                <div className="bg-white shadow-md rounded-lg overflow-hidden text-left row">
                    <h2 className="text-2xl font-bold mb-0 p-4 text-center">{jobData?.name}</h2>
                    <p className="text font-bold mb-0 p-4">{jobData?.description}</p>
                    <div className="mb-4 col-md-12">
                        <ul>
                            {jobData?.subtasks.map((task, index) => (
                                <li key={index} style={{ background: "#DFDCDC", marginRight: "10px", borderRadius: "10px" }} className="mb-2">
                                    <div className="flex items justify-between">
                                        <section className="w-full divide-y rounded divide-slate-200">
                                            <details className="p-4 group relative">
                                                <summary
                                                // className="[&::-webkit-details-marker]:hidden relative pr-8 font-medium list-none cursor-pointer text-slate-700 focus-visible:outline-none transition-colors duration-300 group-hover:text-slate-900 "
                                                >
                                                    <b>{task.name}</b>

                                                </summary>
                                                <p className="mt-4 text-slate-500">
                                                    {task.description}
                                                </p>
                                            </details>

                                        </section>
                                    </div>
                                </li>
                            ))}
                        </ul>


                        <div className="p-0">
                            {!isInstructor && <>
                                {jobApproved ? (
                                    <>
                                        <button
                                            disabled
                                            class="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded-full shadow-lg focus-visible:outline-none whitespace-nowrap bg-green-500 shadow-green-200 hover:bg-green-600 hover:shadow-md hover:shadow-green-200 focus:bg-green-700 focus:shadow-md focus:shadow-green-200 disabled:cursor-not-allowed disabled:border-sky-300 disabled:bg-green-300 disabled:shadow-none"
                                        >
                                            <span>Approved</span>
                                        </button>
                                    </>
                                ) :
                                    (<>
                                        {applied ? (<>
                                            <button
                                                disabled
                                                class="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded-full shadow-lg focus-visible:outline-none whitespace-nowrap bg-green-500 shadow-green-200 hover:bg-green-600 hover:shadow-md hover:shadow-green-200 focus:bg-green-700 focus:shadow-md focus:shadow-green-200 disabled:cursor-not-allowed disabled:border-sky-300 disabled:bg-green-300 disabled:shadow-none"
                                            >
                                                <span>Applied</span>
                                            </button>
                                        </>) :
                                            (<>
                                                {bloading ? (
                                                    <>
                                                        <button class="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded-full focus-visible:outline-none justify-self-center whitespace-nowrap bg-sky-500 hover:bg-sky-600 focus:bg-sky-700 disabled:cursor-not-allowed disabled:border-sky-300 disabled:bg-sky-300 disabled:shadow-none disabled">
                                                            <span>Apply for the Job</span>
                                                            <span class="relative only:-mx-6">
                                                                <svg class="w-5 h-5 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" role="graphics-symbol" aria-labelledby="title-36 desc-36">
                                                                    <title id="title-36"></title>
                                                                    <desc id="desc-36">
                                                                    </desc>
                                                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                </svg>
                                                            </span>
                                                        </button>

                                                    </>) :
                                                    (<>
                                                        <button
                                                            onClick={() => apply(jobId)}
                                                            class="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded-full shadow-lg focus-visible:outline-none whitespace-nowrap bg-sky-500 shadow-sky-200 hover:bg-sky-600 hover:shadow-md hover:shadow-sky-200 focus:bg-sky-700 focus:shadow-md focus:shadow-sky-200 disabled:cursor-not-allowed disabled:border-sky-300 disabled:bg-sky-300 disabled:shadow-none">
                                                            <span>Apply for the Job</span>
                                                        </button>
                                                    </>)}
                                            </>)
                                        }
                                    </>)}
                            </>}

                            <hr></hr>
                            {isInstructor && applicants.length > 0 &&
                                (<>
                                    <div className="p-4">
                                        <h1 className="text-2xl font-bold mb-4">Instructor Panel</h1>
                                        <div className="overflow-x-auto">
                                            <table className="min-w-full">
                                                <thead>
                                                    <tr className="bg-gray-200">
                                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Applicant's Name</th>
                                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Action</th>
                                                        <th className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">Requested At</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="bg-white divide-y divide-gray-200">
                                                    {applicants.map((applicant) => (
                                                        <tr key={applicant.id}>
                                                            <td className="px-6 py-4 whitespace-nowrap">{applicant.name}</td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                {!applicant.task.approved ? (
                                                                    <div>
                                                                        {abLoading ? (
                                                                            <button disabled className="inline-flex items-center justify-center h-10 gap-2 px-5 text-sm font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none whitespace-nowrap bg-lime-500 hover:bg-lime-600 focus:bg-lime-700 disabled:cursor-not-allowed disabled:border-lime-300 disabled:bg-lime-300 disabled:shadow-none">
                                                                                <span>Approve</span>
                                                                                <span className="relative only:-mx-5">
                                                                                    <svg className="w-5 h-5 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" role="graphics-symbol" aria-labelledby="title-10 desc-10">
                                                                                        <title id="title-10"></title>
                                                                                        <desc id="desc-10"></desc>
                                                                                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                                    </svg>
                                                                                </span>
                                                                            </button>
                                                                        ) : (
                                                                            <button onClick={() => handleApprove(applicant.id, applicant.task.id)} className="inline-flex items-center justify-center h-10 gap-2 px-5 text-sm font-medium tracking-wide text-white transition duration-300 rounded shadow-md focus-visible:outline-none whitespace-nowrap bg-lime-500 shadow-lime-200 hover:bg-lime-600 hover:shadow-sm hover:shadow-lime-200 focus:bg-lime-700 focus:shadow-sm focus:shadow-lime-200 disabled:cursor-not-allowed disabled:border-lime-300 disabled:bg-lime-300 disabled:shadow-none">
                                                                                <span>Approve</span>
                                                                            </button>
                                                                        )}
                                                                    </div>
                                                                ) : (
                                                                    abLoading ? (

                                                                        <button disabled class="inline-flex items-center justify-center h-10 gap-2 px-5 text-sm font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none whitespace-nowrap bg-rose-500 hover:bg-rose-600 focus:bg-rose-700 disabled:cursor-not-allowed disabled:border-rose-300 disabled:bg-rose-300 disabled:shadow-none">
                                                                            <span>Disapprove</span>
                                                                            <span class="relative only:-mx-5">
                                                                                <svg class="w-5 h-5 text-white animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" role="graphics-symbol" aria-labelledby="title-10 desc-10">
                                                                                    <title id="title-10"></title>
                                                                                    <desc id="desc-10">

                                                                                    </desc>
                                                                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                                                </svg>
                                                                            </span>
                                                                        </button>

                                                                    ) : (

                                                                        <button onClick={() => handleDisapprove(applicant.id, applicant.task.id)} class="inline-flex items-center justify-center h-10 gap-2 px-5 text-sm font-medium tracking-wide text-white transition duration-300 rounded shadow-md focus-visible:outline-none whitespace-nowrap bg-rose-500 shadow-rose-200 hover:bg-rose-600 hover:shadow-sm hover:shadow-rose-200 focus:bg-rose-700 focus:shadow-sm focus:shadow-rose-200 disabled:cursor-not-allowed disabled:border-rose-300 disabled:bg-rose-300 disabled:shadow-none">
                                                                            <span>Disapprove</span>
                                                                        </button>
                                                                    )
                                                                )}
                                                            </td>
                                                            <td className="px-6 py-4 whitespace-nowrap">
                                                                {new Date(applicant.task.timeStamp.seconds * 1000).toLocaleString()}
                                                            </td>
                                                        </tr>
                                                    ))}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </>)
                            }

                        </div>

                    </div>
                </div>
            </div >
        </>
    );
};

export default JobScreen;
