import Carousel from 'react-bootstrap/Carousel';
import slide1 from '../../image/manageService.jpg';
import slide2 from '../../image/application.jpg';
import slide3 from '../../image/training.jpg';
import './Banner.css';
import Typography from '@mui/material/Typography';

function Banner() {
  return (
    <Carousel fade interval={1500} controls={false} indicators={false} >
      <Carousel.Item>
        <img src={slide1} alt="First slide" className="carousel-image" />
        <Carousel.Caption className="centered-caption" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8' }}>
          <h1>Managed Service</h1>
          <Typography variant="body4" color="text.secondary" className="card-description">
            Unlock peace of mind with our expertly tailored Managed Service solutions, optimizing your IT infrastructure seamlessly.
          </Typography>
        </Carousel.Caption>


      </Carousel.Item>
      <Carousel.Item>
        <img src={slide2} alt="Second slide" className="carousel-image" />
        <Carousel.Caption className="centered-caption" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8' }}>
          <h1>Application & Automation</h1>
          <Typography variant="body4" color="text.secondary" className="card-description">
            Boost efficiency and productivity with our cutting-edge Application and Automation services, streamlining your business processes for success.
          </Typography>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img src={slide3} alt="Third slide" className="carousel-image" />
        <Carousel.Caption className="centered-caption" style={{ backgroundColor: 'rgba(255, 255, 255, 0.8' }}>
          <h1>Training Service</h1>
          <Typography variant="body4" color="text.secondary" className="card-description">
            Empower your team for success in the ever-evolving tech landscape with our industry-leading Training programs.
          </Typography>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>


  );
}

export default Banner;
