import { sendPasswordResetEmail } from "firebase/auth";
import React from "react";
import { auth } from "../firebase";
import { useHistory } from "react-router-dom";

function ResetPassword() {
    const history = useHistory();

    const handleSubmit = async (e) => {
        e.preventDefault()
        const emalVal = e.target.email.value;
        sendPasswordResetEmail(auth, emalVal).then(data => {
            alert("Check your gmail")
            history.push("/")
        }).catch(err => {
            alert(err.code)
        })
    }
    return (
        <div className="flex flex-col items-center justify-center min-h-screen">
            <h1 className="text-3xl font-bold mb-6">Forgot Password</h1>
            <form onSubmit={(e) => handleSubmit(e)} className="w-full max-w-md">
                <input
                    type="email"
                    name="email"
                    className="w-full px-4 py-2 border rounded-md mb-4"
                    placeholder="Enter your email"
                />
                <button
                    type="submit"
                    className="w-full bg-blue-500 text-white py-2 rounded-md hover:bg-blue-600 focus:outline-none focus:shadow-outline-blue"
                >
                    Reset
                </button>
            </form>
        </div>

    )
}
export default ResetPassword;