import React, { useState, useEffect } from 'react';
import { FaRegTrashAlt, FaRegEdit } from 'react-icons/fa';
import {

    updateDoc,
    doc,
    getDoc,

} from 'firebase/firestore';
import { db } from '../firebase';


const style = {
    li: `flex justify-between bg-slate-200 p-6 my-2 mx-2 capitalize`,
    liComplete: `flex justify-between bg-slate-400 p-6 my-2 mx-2 capitalize`,
    row: `flex`,
    text: `ml-2 cursor-pointer`,
    textComplete: `ml-2 cursor-pointer line-through`,
    buttonContainer: `flex`, // Style for the button container
    buttonEdit: `cursor-pointer flex items-center ml-2`, // Style for the edit button
    buttonDelete: `cursor-pointer flex items-center ml-2`, // Style for the delete button
    input: `border p-2 ml-2 w-40 text-xl`, // Style for the input field
};


const Todo = ({ todo, toggleComplete, deleteTodo, updateTodo, isTaskActive, setIsTaskActive, delStatus }) => {
    const fetchUpdatedTodo = async (id) => {
        const todoRef = doc(db, 'todos', id);
        const todoSnapshot = await getDoc(todoRef);
        const updatedTodo = { id: todoSnapshot.id, ...todoSnapshot.data() };
        return updatedTodo;
    };
    const [isEditing, setEditing] = useState(false);
    const [updatedText, setUpdatedText] = useState(todo.text);
    const [timer, setTimer] = useState(0);
    const [intervalId, setIntervalId] = useState(null);
    const [completed, setCompleted] = useState(false);
    const [startTime, setStartTime] = useState(null);
    const [pausedTime, setPausedTime] = useState(0);


    useEffect(() => {
        // Clear existing interval before setting a new one.
        if (intervalId) {
            clearInterval(intervalId);
        }
        if (delStatus) {
            clearInterval(intervalId);
            setIsTaskActive(false);
            setCompleted(true);
        }
        const initializeTimer = () => {
            if (todo.startTime && typeof todo.startTime.toDate === 'function' && !todo.completed && !todo.paused) {
                const now = new Date();
                const startTime = todo.startTime.toDate();
                const elapsedSeconds = Math.floor((now - startTime) / 1000);
                setTimer(elapsedSeconds);
            }
        };

        initializeTimer();

        // Set up interval only if the task is not completed or paused.
        let newIntervalId;
        if (!todo.completed && !todo.paused) {
            newIntervalId = setInterval(() => {
                setTimer((prev) => prev + 1);
            }, 1000);
        }

        setIntervalId(newIntervalId); // Store the new interval ID

        // Clean up the interval on component unmount.
        return () => {
            if (newIntervalId) clearInterval(newIntervalId);
        };
    }, [todo, intervalId]); // Include intervalId in dependencies if it's a state.

    // Ensure that the start, pause, resume, and complete functions also manage the interval correctly.
    useEffect(() => {
        if (todo.completed) {
            const endTime = todo.endTime.toDate();
            const startTime = todo.startTime.toDate();
            const elapsedSeconds = Math.floor((endTime - startTime) / 1000);
            setTimer(elapsedSeconds);
        }
    }, [todo]);


    const handleUpdate = () => {
        updateTodo(todo.id, updatedText);
        setEditing(false);
    };

    const updateTodoStartTime = async (id, start) => {
        await updateDoc(doc(db, 'todos', id), {
            startTime: start,
        });
    };

    const startTask = () => {
        if (isTaskActive) {
            alert('A task is already running. Please pause or complete the existing task.');
            return;
        }
        const startTime = new Date();
        updateTodoStartTime(todo.id, startTime);

        // Set initial timer value
        setTimer(0);

        // Start the timer
        const id = setInterval(() => {
            setTimer((prev) => prev + 1);
        }, 1000);
        setIntervalId(id);
        setIsTaskActive(true);
    };



    const updateTodoPauseTask = async (id, end) => {
        await updateDoc(doc(db, 'todos', id), {
            paused: true,
            endTime: end,
        });
    };


    const pauseTask = () => {
        clearInterval(intervalId);
        const now = new Date();
        const startTime = todo.startTime.toDate();
        const elapsedSeconds = Math.floor((now - startTime) / 1000);
        setPausedTime(elapsedSeconds);

        updateTodoPauseTask(todo.id, now);
        setTimer(0);
        setIsTaskActive(false);
    };

    const updateTodoNotPauseTask = async (id) => {
        await updateDoc(doc(db, 'todos', id), {
            paused: false,
            endTime: null,
        });
    };

    const resumeTask = () => {
        if (isTaskActive) {
            alert('A task is already running. Please pause or complete the existing task.');
            return;
        }
        const now = new Date();
        const startTime = new Date(now - pausedTime * 1000); // Calculate start time based on paused time
        updateTodoStartTime(todo.id, startTime);
        const id = setInterval(() => setTimer((prev) => prev + 1), 1000);
        setIntervalId(id);
        updateTodoNotPauseTask(todo.id);
        setIsTaskActive(true);
    };

    const updateTodoEndTask = async (id, end) => {
        await updateDoc(doc(db, 'todos', id), {
            endTime: end,
            completed: true,
        });
    };

    const completeTask = async () => {
        clearInterval(intervalId);
        const now = new Date();
        await updateTodoEndTask(todo.id, now);
        setCompleted(true);

        // Fetch the updated todo after completing the task
        const updatedTodo = await fetchUpdatedTodo(todo.id);
        updateTodo(updatedTodo.id, updatedTodo.text);
        setIsTaskActive(false);
    };


    const formatTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${hours}h ${minutes}m ${seconds}s`;
    };

    useEffect(() => {
        fetchUpdatedTodo(todo.id);
    }, []);
    const updateButtonContent = () => {
        return `Time: ${formatTime(timer)}`;
    };

    const handleDeleteTodo = (id) => {
        clearInterval(intervalId);
        deleteTodo(id);
        setIsTaskActive(false);
        setCompleted(true);
    };

    return (
        <div className="ml-[-32px] mb-8">
            <li className={`flex flex-col ${todo.completed ? 'bg-slate-400' : 'bg-slate-200'} p-6 my-2 mx-2 capitalize`}>
                <div className="flex">
                    {isEditing ? (
                        <input
                            className="border p-2 ml-2 w-40 text-xl"
                            type='text'
                            value={updatedText}
                            onChange={(e) => setUpdatedText(e.target.value)}
                        />
                    ) : (
                        <button
                            className={`ml-2 ${todo.completed ? 'line-through' : ''} cursor-pointer`}
                            disabled
                            style={{ cursor: 'default' }}
                        >
                            {todo.text}
                        </button>

                    )}

                </div>
                <div className="flex items-center justify-between mt-4">
                    {todo.completed ? (
                        <>
                            <button disabled className="text-sm">{updateButtonContent()}</button>
                            <button
                                onClick={() => deleteTodo(todo.id)}
                                className="bg-red-700 hover:bg-red-800 text-white font-bold py-1 px-2 rounded-full ml-2"
                            >
                                Delete
                            </button>
                        </>
                    ) : (
                        <>
                            {isEditing ? (
                                <>
                                    <button
                                        onClick={() => setEditing(false)}
                                        className="bg-red-500 hover:bg-red-700 text-white font-bold py-1 px-2 rounded-full ml-2"
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        onClick={handleUpdate}
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded-full ml-2"
                                    >
                                        Update
                                    </button>
                                </>
                            ) : (
                                <>
                                    {todo.startTime ? (
                                        <div className="flex items-center">
                                            <button disabled className="text-sm">{`Time: ${formatTime(timer)}`}</button>
                                            {!todo.paused ? (
                                                <>
                                                    <button onClick={() => setEditing(true)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded-full ml-2">
                                                        Edit
                                                    </button>
                                                    <button onClick={pauseTask} className="bg-yellow-600 hover:bg-yellow-800 text-white font-bold py-1 px-2 rounded-full ml-2">
                                                        Pause
                                                    </button>
                                                    <button onClick={completeTask} className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded-full ml-2">
                                                        Finish
                                                    </button>
                                                </>
                                            ) : (
                                                <>
                                                    <button onClick={() => setEditing(true)} className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded-full ml-2">
                                                        Edit
                                                    </button>
                                                    <button onClick={resumeTask} className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded-full ml-2">
                                                        Resume
                                                    </button>
                                                </>

                                            )}
                                        </div>
                                    ) : (
                                        <div className="flex items-center">
                                            <button onClick={startTask} className="bg-green-500 hover:bg-green-700 text-white font-bold py-1 px-2 rounded-full ml-2">
                                                Start
                                            </button>
                                        </div>
                                    )}
                                    {!todo.taskId
                                        &&
                                        <button onClick={() => handleDeleteTodo(todo.id)} className="ml-2 cursor-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-5 w-5 text-red-500">
                                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                                            </svg>
                                        </button>
                                    }

                                </>
                            )}
                        </>
                    )}
                </div>
            </li>
        </div>

    );
};

export default Todo;
