import React from 'react';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardMedia from '@mui/material/CardMedia';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useSpring, animated } from 'react-spring';

const calc = (x, y) => [
    -(y - window.innerHeight / 2) / 20,
    (x - window.innerWidth / 2) / 20,
    1.1,
];
const trans = (x, y, s) =>
    `perspective(4000px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

const AnimatedCard = ({ card }) => {
    const [props, set] = useSpring(() => ({
        xys: [0, 0, 1],
        config: { mass: 5, tension: 250, friction: 40 },
    }));

    return (
        <div className="animated-card-container">
            <animated.div
                className="animated-card"
                onMouseMove={({ clientX: x, clientY: y }) => set({ xys: calc(x, y) })}
                onMouseLeave={() => set({ xys: [0, 0, 1] })}
                style={{ transform: props.xys.interpolate(trans) }}
            >
                <Card className="custom-card h-500 mb-20">
                    <CardActionArea>
                        <CardMedia
                            component="img"
                            height="200"
                            image={card.image}
                            className="object-cover fluid"
                            alt={card.name}
                        />
                        <CardContent className="card-content h-500"> {/* Set a fixed height for card content */}
                            <Typography gutterBottom variant="h5" component="div" className="card-title">
                                {card.name}
                            </Typography>
                            <Typography variant="body2" color="text-secondary" className="card-description">
                                {card.content}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                </Card>

            </animated.div>
        </div>
    );
};

export default AnimatedCard;
