import React, { useState, useEffect } from 'react';
import { AiOutlinePlus } from 'react-icons/ai';
import Todo from './Todo';
import { isToday, isWithinInterval, subDays } from 'date-fns';
import { db } from '../firebase';
import {
    query,
    collection,
    onSnapshot,
    updateDoc,
    doc,
    addDoc,
    deleteDoc,
    where,
    getDocs,
    orderBy
} from 'firebase/firestore';
import { Link, useHistory } from 'react-router-dom';
import Loader from '../Loader/Loader.jsx';
import { Timestamp } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';

const style = {
    bg: `p-4 bg-gradient-to-r from-[#00000] to-[#1CB5E0]`,
    container: `bg-slate-100 max-w-[800px] w-full m-auto rounded-md shadow-xl p-2`,
    heading: `text-3xl font-bold text-center text-gray-800 p-2`,
    // myMetricButton: `text-1xl font-bold text-left text-gray-800 p-2`,
    form: `flex justify-between`,
    input: `border p-2 w-full text-xl`,
    button: `border p-4 ml-2 bg-blue-500 text-slate-100`,
    count: `text-center p-2`,
};

function TodoMain() {
    const [loading, setLoading] = useState(false);
    const [userInfo, setUserInfo] = useState('');
    useEffect(() => {
        setLoading(true);
        const checkEmployee = async () => {
            const userRef = collection(db, "users");
            const userDoc = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
            const userData = await getDocs(userDoc);
            if (userData.empty) {
                history.push('/');
            }
            userData.forEach((doc) => {
                const userData = doc.data();
                if (!userData.employee) {
                    history.push('/');
                }
            });
        }
        checkEmployee();
        setLoading(false);
    }, []);

    const isToday = (someDate) => {
        const today = new Date();
        return someDate.getDate() == today.getDate() &&
            someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear();
    };


    const isThisMonth = (someDate) => {
        const today = new Date();
        return someDate.getMonth() == today.getMonth() &&
            someDate.getFullYear() == today.getFullYear();
    };



    const history = useHistory();
    useEffect(() => {
        // Corrected the condition here
        if (sessionStorage.getItem("user_email") === "" || sessionStorage.getItem("user_email") === null) {
            history.push('/');
        }
    }, [history]);
    const [todos, setTodos] = useState([]);
    const [input, setInput] = useState('');
    const [totalTime, setTotalTime] = useState(0);
    const [isTaskActive, setIsTaskActive] = useState(false);

    // Create todo
    const createTodo = async (e) => {
        e.preventDefault(e);
        if (input === '') {
            alert('Please enter a valid todo');
            return;
        }
        const initiatedToday = new Date().getDate();
        await addDoc(collection(db, 'todos'), {
            user: sessionStorage.user_id,
            text: input,
            completed: false,
            startTime: null,
            endTime: null,
            paused: false,
            initiateDate: initiatedToday,
        });
        setInput('');
    };
    const [uName, setUname] = useState('');
    const createTodoInstant = async (e) => {
        e.preventDefault(e);
        const userRef = collection(db, "users");
        const userDoc = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
        const userData = await getDocs(userDoc);
        userData.forEach((doc) => {
            const userData = doc.data();
            setUname(userData.Name);
        });
        const currentTimestampInSeconds = Math.floor(Date.now() / 1000);
        const today = new Date().getDate();
        await addDoc(collection(db, 'todos'), {
            user: sessionStorage.user_id,
            text: currentTimestampInSeconds,
            completed: false,
            startTime: null,
            endTime: null,
            paused: false,
            initiateDate: today,
        });
    };

    const formatTime = (totalSeconds) => {
        const hours = Math.floor(totalSeconds / 3600);
        const minutes = Math.floor((totalSeconds % 3600) / 60);
        const seconds = totalSeconds % 60;

        return `${hours}h ${minutes}m ${seconds}s`;
    };

    // Read todo from firebase




    const toggleComplete = async (todo) => {
        await updateDoc(doc(db, 'todos', todo.id), {
            completed: !todo.completed,
        });
    };

    const deleteTodo = async (id, force = false) => {
        if (!force) {
            const confirmDelete = window.confirm('Are you sure you want to delete this task?');
            if (confirmDelete) {
                // User confirmed, proceed with deletion
                await deleteDoc(doc(db, 'todos', id));
            }
        }

    };

    const updateTodo = async (id, newText) => {
        await updateDoc(doc(db, 'todos', id), {
            text: newText,
        });
    };

    const [del, setDel] = useState(false);
    const endTask = async (e, id) => {
        e.preventDefault();
        setDel(false);
        const end = new Date();
        await updateDoc(doc(db, 'todos', id), {
            endTime: end,
            paused: false,
            completed: true,
        });
        setDel(true);
    };


    const [totalDayTime, setTotalDayTime] = useState(0);
    const [totalMonthTime, setTotalMonthTime] = useState(0);

    useEffect(() => {
        setLoading(true);

        const today = new Date();
        const todayDate = today.getDate();

        const q = query(
            collection(db, 'todos'),
            where('user', '==', sessionStorage.user_id),
            where('initiateDate', '==', todayDate),
        );

        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            let todosArr = [];
            let totalElapsedTime = 0;
            let totalDayTime = 0;
            let totalMonthTime = 0;
            let taskIds = new Set(); // Create a set to track unique task IDs

            querySnapshot.forEach((doc) => {
                const todoData = { ...doc.data(), id: doc.id };
                // Check if taskId is already in the set
                if (!taskIds.has(todoData.taskId)) {
                    taskIds.add(todoData.taskId); // Add taskId to the set
                    todosArr.push(todoData); // Add todoData to the array only if taskId is unique
                } else {
                    deleteTodo(todoData.taskId, true);
                }
                if (todoData.completed) {
                    const endTime = todoData.endTime?.toDate();
                    const startTime = todoData.startTime?.toDate();

                    if (endTime && startTime) {
                        const elapsedSeconds = Math.floor((endTime - startTime) / 1000);
                        totalElapsedTime += elapsedSeconds;

                        if (isToday(endTime)) {
                            totalDayTime += elapsedSeconds;
                        }

                        if (isThisMonth(endTime)) {
                            totalMonthTime += elapsedSeconds;
                        }
                    }
                }
            });

            // At this point, todosArr contains only unique tasks by taskId
            setTodos(todosArr);
            setTotalTime(totalElapsedTime);
            setTotalDayTime(totalDayTime);
            setTotalMonthTime(totalMonthTime);
            setLoading(false);
        });
        return () => unsubscribe();

    }, []);

    const [commitmentHour, setCommitmentHour] = useState(0);
    const monthNum = new Date().getMonth() + 1;
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August",
        "September", "October", "November", "December"];


    useEffect(() => {
        const fetchData = async () => {
            try {
                const userRef = collection(db, "users");
                const userDoc = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
                const userData = await getDocs(userDoc);

                if (userData.empty) {
                    toast.error("Invalid User");
                    setLoading(false);
                    return;
                }

                userData.forEach((doc) => {
                    const userData = doc.data();
                    setCommitmentHour(userData.commitmentHours[monthNum]);
                });

                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Error fetching data");
                setLoading(false);
            }
        };

        if (!sessionStorage.getItem("user_email") !== "" && sessionStorage.getItem("user_email") == null) {
            history.push('/');
        }
        // if (commitmentHour === 0) {
        //     history.push('/thScheduler');
        // }
        fetchData();
    }, []);

    useEffect(() => {
        const fetchJobTasks = async () => {
            try {
                const userRef = collection(db, "users");
                const userQuery = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
                const querySnapshot = await getDocs(userQuery);

                if (!querySnapshot.empty) {
                    const userData = querySnapshot.docs[0].data();
                    if (Array.isArray(userData.tasks)) {
                        for (const task of userData.tasks) {
                            if (task.approved) {
                                const jobsRef = collection(db, "Jobs");
                                const jobQuerySnapshot = await getDocs(jobsRef);

                                if (!jobQuerySnapshot.empty) {
                                    jobQuerySnapshot.forEach(jobDoc => {
                                        const jobId = jobDoc.id;
                                        const jobData = jobDoc.data();
                                        if (jobId === task.id) {
                                            const jobName = jobData.name ? jobData.name : task.name;
                                            createTodoJob(task.id, jobName);
                                        }
                                    });
                                } else {
                                    console.log(`No jobs found in the Jobs collection.`);
                                }
                            }
                        }
                    } else {
                        console.log("No tasks found for the user.");
                    }
                } else {
                    console.log("No tasks found for the user.");
                }
            } catch (error) {
                console.error("Error fetching tasks:", error);
            }
        };
        fetchJobTasks();
    }, []);

    const createTodoJob = async (id, name) => {
        const initiatedToday = new Date().getDate();
        try {
            const todosRef = collection(db, 'todos');
            const todosQuery = query(
                todosRef,
                where("taskId", "==", id),
                where("user", "==", sessionStorage.user_id),
                where("initiateDate", "==", initiatedToday) // Ensure we are only looking for tasks initiated today
            );

            const todosSnapshot = await getDocs(todosQuery);

            // Check if a task already exists for the current user and taskId
            if (!todosSnapshot.empty) {
                console.log(`Task with taskId ${id} already exists in todos collection for current user.`);
                // Existing task found, handle accordingly. For example, you might want to update it or simply log a message.
            } else {
                // No existing task found, proceed to add a new one
                await addDoc(todosRef, {
                    user: sessionStorage.user_id,
                    taskId: id,
                    text: name,
                    completed: false,
                    startTime: null,
                    endTime: null,
                    paused: false,
                    initiateDate: initiatedToday,
                });
                console.log('New task added successfully');
            }
        } catch (error) {
            console.error('Error creating todo job:', error);
        }
    };



    return (

        loading ? (
            <Loader />
        ) : (
            <div className={style.bg} style={{ overflow: "hidden" }}>
                <div className={style.container}>
                    <h3 className={style.heading}>TH Metric</h3>
                    {commitmentHour > 0 ? (
                        <>
                            <h4 className="mb-2 text-center">Commitment for {monthNames[monthNum - 1]} is: <b>{commitmentHour} Hours</b></h4>
                        </>) : (
                        <>
                            <h4 className="mb-2 text-center"><button variant="outlined" align="center" style={{ padding: "16px" }}>
                                Please Visit
                                <Link to="/thScheduler"> TH Scheduler </Link>
                                to Book Your Commitments
                            </button></h4>
                        </>)}

                    <form onSubmit={createTodo} className={style.form}>
                        <input
                            value={input}
                            onChange={(e) => setInput(e.target.value)}
                            className={style.input}
                            type='text'
                            placeholder='Add Task'
                        />
                        <button className={style.button}>
                            <AiOutlinePlus size={30} />
                        </button>
                    </form>

                    <div style={{ marginTop: '10px', marginLeft: "6px" }}>

                        {todos.some(todo => !todo.completed && todo.startTime && !todo.paused) && (
                            <form onSubmit={(e) => endTask(e, todos.find(todo => !todo.completed && todo.startTime && !todo.paused).id)} className={style.form}>
                                <button type='submit' className="bg-transperent hover:bg-blue-100 text-blue-700 font-semibold hover:text-blue py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                                    End Task
                                </button>
                            </form>
                        )}

                        {!todos.some(todo => !todo.completed && todo.startTime && !todo.paused) && (
                            <form onSubmit={createTodoInstant} className={style.form}>
                                <button className="bg-transperent hover:bg-blue-100 text-blue-700 font-semibold hover:text-blue py-2 px-4 border border-blue-500 hover:border-transparent rounded">
                                    Start a Task Instantly
                                </button>
                            </form>
                        )}


                    </div>

                    <ul>
                        {todos
                            .map((todo, index) => (
                                <Todo
                                    key={index}
                                    todosData={todos}
                                    todo={todo}
                                    toggleComplete={toggleComplete}
                                    deleteTodo={deleteTodo}
                                    updateTodo={updateTodo}
                                    isTaskActive={isTaskActive}
                                    setIsTaskActive={setIsTaskActive}
                                    delStatus={del}
                                />
                            ))}
                    </ul>
                    <div className="overflow-x-auto mt-6">
                        <table className="table-auto w-full">
                            <thead>
                                <tr className="bg-gray-200 text-gray-600 uppercase text-sm leading-normal">
                                    <th className="py-3 px-6 text-left">Tasks Completed</th>
                                    <th className="py-3 px-6 text-left">Total Time</th>
                                </tr>
                            </thead>
                            <tbody className="text-gray-600 text-sm font-light">
                                <tr className="border-b border-gray-200 hover:bg-gray-100">
                                    <td className="py-3 px-6 text-left whitespace-nowrap">Today</td>
                                    <td className="py-3 px-6 text-left">{formatTime(totalDayTime)}</td>
                                </tr>

                            </tbody>
                        </table>
                        <Link to="/myMetric">
                            <button className='bg-transparent hover:bg-blue-500 text-blue-700 font-semibold hover:bg-blue-400 py-2 px-4 border border-blue-500 hover:border-transparent rounded' style={{ marginTop: "10px" }}>
                                See All</button>
                        </Link>
                    </div>
                </div>
            </div >
        )
    );

}

export default TodoMain;