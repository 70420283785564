import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { auth, db, storage } from '../firebase.js'
import { doc, getDoc, getDocs, updateDoc, collection, deleteDoc, query, where } from 'firebase/firestore';
import Loader from '../Loader/Loader.jsx';
import { ToastContainer, toast } from 'react-toastify';
import Commitment from './Commitment.jsx';
import THScheduler from './THScheduler.jsx';

const SchedulerMain = () => {
    const [loading, setLoading] = useState(true);
    const history = useHistory();
    const [commitmentHour, setCommitmentHour] = useState(0);
    const monthNum = new Date().getMonth() + 1;
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August",
        "September", "October", "November", "December"];

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userRef = collection(db, "users");
                const userDoc = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
                const userData = await getDocs(userDoc);

                if (userData.empty) {
                    toast.error("Invalid User");
                    setLoading(false);
                    return;
                }

                userData.forEach((doc) => {
                    const userData = doc.data();
                    if (userData.commitmentHours[monthNum]) {
                        setCommitmentHour(userData.commitmentHours[monthNum]);
                    }
                });

                setLoading(false);
            } catch (error) {
                console.error("Error fetching data:", error);
                toast.error("Error fetching data");
                setLoading(false);
            }
        };

        if (!sessionStorage.getItem("user_email") !== "" && sessionStorage.getItem("user_email") == null) {
            history.push('/');
        }

        // Simulating a 2-second delay with setTimeout
        setTimeout(() => {
            fetchData();
            getEvents();
        });
    }, []);

    const [totalHour, setTotalHour] = useState(0);
    const [totalMinute, setTotalMinute] = useState(0);
    const calculateTotalTimeFromArray = async (eventsArray) => {
        if (!eventsArray || eventsArray.length === 0) {
            setTotalHour(0);
            setTotalMinute(0);
        }
        let totalHour = 0;
        let totalMinute = 0;

        eventsArray.forEach((event) => {
            const startTime = new Date(event.start);
            const endTime = new Date(event.end);

            if (!isNaN(startTime) && !isNaN(endTime) && event.color === '#00FF00') {
                const timeDiffMilliseconds = endTime - startTime;
                const totalTimeInMinutes = timeDiffMilliseconds / (1000 * 60);

                totalHour += Math.floor(totalTimeInMinutes / 60);
                totalMinute += Math.floor(totalTimeInMinutes % 60);
            }
        });

        // Add to the previous values
        setTotalHour(totalHour);
        setTotalMinute(totalMinute);
    };
    const [events, setEvents] = useState();
    const [userQuerySnapshot, setUserQuerySnapshot] = useState(null);
    const getEvents = async () => {
        try {
            const userRef = collection(db, "users");
            const userQuery = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
            const querySnapshot = await getDocs(userQuery);

            if (!querySnapshot.empty) {
                setUserQuerySnapshot(querySnapshot);
                const userData = querySnapshot.docs[0].data();
                // Convert userData.commits to the desired format

                const formattedCommits = userData.commits.map((commit) => {
                    const startTime = new Date(commit.start.seconds * 1000);
                    const endTime = new Date(commit.end.seconds * 1000);
                    let color = '#00FF00';
                    if (commit.color && commit.color === '#900000') {
                        color = '#900000';
                    }
                    return {
                        event_id: commit.event_id,
                        title: commit.title,
                        start: startTime,
                        end: endTime,
                        color: color
                    };
                }).filter((event) => {
                    // Filter out events that are not from this month
                    const currentDate = new Date();
                    const currentMonth = currentDate.getMonth();
                    const eventMonth = event.start.getMonth();

                    return currentMonth === eventMonth;
                });
                calculateTotalTimeFromArray(formattedCommits);
                setEvents(formattedCommits);
            } else {
                toast.error("Invalid User");
            }
        } catch (error) {
            console.error("Error getting events:", error);
            // toast.error("Error getting events. Please try again.");
        } finally {
            setLoading(false);
        }
    };


    return (
        <div style={{ marginTop: '10px' }}>
            {loading ? <Loader /> :
                (
                    <div className="mt-4 flex flex-col">
                        <div className="flex flex-wrap justify-center">
                            {/* First Column */}
                            <div className="w-full md:w-1/3 p-4">
                                <h4 className="mb-2 text-center">Commitment for {monthNames[monthNum - 1]} is: <b>{commitmentHour} Hours</b></h4>

                            </div>

                            {/* Second Column */}
                            {commitmentHour > 0 && (
                                <>
                                    <div className="w-full md:w-1/3 p-4">
                                        <h4 className="mb-2 text-center">Commitment Booked {monthNames[monthNum - 1]} is: <b>{totalHour} Hours</b> <b>{totalMinute} Minutes</b></h4>
                                    </div>
                                    <div className="w-full md:w-1/3 p-4">
                                        <Link to="/metric">
                                            <button className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">
                                                TH Metric
                                            </button>
                                        </Link>
                                    </div>
                                </>
                            )}
                        </div>

                        {commitmentHour === 0 ? (
                            <div className="flex flex-col items-center">
                                <Commitment />
                            </div>
                        ) : (
                            <>
                                <THScheduler prevEvents={events} getEvents={getEvents} />

                            </>
                        )}
                    </div>
                )
            }
        </div>
    );
};

export default SchedulerMain;
