import React from "react";
import logo from '../../image/logoSm.jpg'
import {
  MDBFooter,
  MDBContainer,
  MDBIcon,
  MDBBtn
} from 'mdb-react-ui-kit';
import './Footer.css'

const getCurrentYear = () => {
  return new Date().getFullYear();
}

const Footer = () => {

  const today = {
    year: getCurrentYear()
  };

  return (
    <div className="footer-wrapper">
      <MDBFooter className="text-center" color="white" style={{ backgroundColor: "#61bed6", fontWeight: "bold", marginTop: "20px" }}>
        <MDBContainer className="p-4">
          <div className="row">
            <div className="col-md-6">
              <section className="mb-4">
                <img src={logo} alt="Logo" style={{ width: '200px', height: '65px', marginBottom: '10px' }} />
                <p style={{ fontStyle: "italic", opacity: "0.7" }}>
                  Empowering Your Success Through Innovative Technology Solutions - Techavens, Your Partner for Tomorrow's Achievements
                </p>
              </section>
            </div>
            <div className="col-md-6">
              <section className="mb-4">
                <h4>Get In Touch</h4>
                <p>Email: help@techavens.com</p>
                <MDBBtn outline color="light" floating className="m-1 mbicon" href="https://www.facebook.com/profile.php?id=100093128203299" role="button">
                  <MDBIcon fab icon="facebook-f" />
                </MDBBtn>
                <MDBBtn outline color="light" floating className='m-1 mbicon' href='' role='button'>
                  <MDBIcon fab icon="linkedin-in" />
                </MDBBtn>
              </section>
            </div>
          </div>
        </MDBContainer>
        <div className="text-center p-3" style={{ backgroundColor: "rgba(0, 0, 0, 0.2)" }}>
          <span className="text-white" href="">
            ©{today.year} Techavens
          </span>
        </div>
      </MDBFooter>
    </div>
  )
}

export default Footer
