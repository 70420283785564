import React, { useState, useEffect } from "react";
import { auth, db, storage } from '../firebase';
import { doc, getDocs, updateDoc, collection, deleteDoc, query, where } from 'firebase/firestore';
import { ref, getDownloadURL } from 'firebase/storage';

import { Table, TableHead, TableBody, TableRow, TableCell } from "@mui/material";
import Loader from '../Loader/Loader.jsx';
import { ToastContainer, toast } from 'react-toastify';
import { Link, useHistory } from 'react-router-dom';

const User = () => {
    const [users, setUsers] = useState([]);
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState([]);
    const history = useHistory();
    const [cvURLs, setCVURLs] = useState({});

    const fetchUsers = async () => {
        setLoading(true);
        try {
            const usersCollection = collection(db, "users");
            const usersSnapshot = await getDocs(usersCollection);
            const usersList = usersSnapshot.docs.map((doc) => doc.data());
            setId(usersSnapshot.docs.map((doc) => doc.id));
            setUsers(usersList);
            setLoading(false);
        } catch (error) {
            console.error(error);
            setLoading(false);
        }
    };

    useEffect(() => {
        checkAdmin();
        fetchUsers();
    }, []);

    useEffect(() => {
        if (users.length > 0) {
            fetchCVURLs();
        }
    }, [users]);

    const currentUserId = sessionStorage.getItem('user_id');

    const checkAdmin = async () => {
        if (sessionStorage.getItem("user_email" === "") || sessionStorage.getItem("user_email") == null) {
            history.push('/');
        } else {
            const userRef = collection(db, "users");
            const userDoc = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
            const userData = await getDocs(userDoc);
            if (userData.empty) {
                toast.error("Please Register First");
                setLoading(false);
                return;
            }

            userData.forEach((doc) => {
                const userData = doc.data();
                if (userData.isAdmin === false || !userData.isAdmin) {
                    history.push('/');
                }
            });
        }
    };

    const getCVURL = async (userID) => {
        try {
            const pathReference = ref(storage, `/files/${userID}`);
            return getDownloadURL(pathReference); // Return the promise.
        } catch (error) {
            console.error("Error fetching CV URL:", error);
            return null;
        }
    };

    const fetchCVURLs = async () => {
        const cvUrls = {};

        const promises = await users
            .filter((user) => user.UID !== currentUserId)
            .map((user) => {
                return getCVURL(user.UID)
                    .then((url) => {
                        cvUrls[user.UID] = url;
                    })
                    .catch((error) => {
                        console.error("Error fetching CV URL:", error);
                    });
            });

        await Promise.all(promises);

        setCVURLs(cvUrls);
    };

    return (
        <>
            <ToastContainer />
            <div className="p-4 overflow-x-auto"> {/* Add overflow-x-auto class to enable horizontal scrolling on small screens */}
                <h2 className="text-2xl mb-4">All Community Members</h2>
                <Table className="w-full">
                    <TableHead>
                        <TableRow>
                            {loading ? (
                                <div className="centered">
                                    <Loader />
                                </div>
                            ) : (
                                <>
                                    <TableCell className="w-1/6">Name</TableCell>
                                    <TableCell className="w-1/6">Email</TableCell>
                                    <TableCell className="w-1/6">Phone NO</TableCell>
                                    <TableCell className="w-1/6">CV</TableCell>
                                    <TableCell className="w-1/6">Approval Action</TableCell>
                                    <TableCell className="w-1/6">Employee Action</TableCell>
                                    <TableCell className="w-1/6">Delete User</TableCell>
                                </>
                            )}
                        </TableRow>
                        {users.length === 0 && !loading && (
                            <h3 className="mt-4 font-bold">No users found</h3>
                        )}
                    </TableHead>
                    <TableBody>
                        {users.map((user, index) => (
                            user.UID !== currentUserId && (
                                <TableRow key={index}>
                                    <TableCell>{user.Name}</TableCell>
                                    <TableCell>{user.Email}</TableCell>
                                    <TableCell>{user.Phone}</TableCell>
                                    <TableCell>
                                        {user.UID !== currentUserId && cvURLs[user.UID] ? (
                                            <a href={cvURLs[user.UID]} target="_blank" rel="noopener noreferrer">
                                                CV Link
                                            </a>
                                        ) : (
                                            user.UID !== currentUserId ? "Not Found" : null
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {user.approved ? (
                                            <p className="text-green-500 font-bold">Approved</p>
                                        ) : (
                                            <button
                                                className="bg-green-500 text-white py-2 px-4 rounded"
                                                onClick={async () => {
                                                    setLoading(true);
                                                    try {
                                                        const userRef = doc(db, "users", id[index]);
                                                        await updateDoc(userRef, {
                                                            approved: true,
                                                        });
                                                        setLoading(false);
                                                        toast.success("User approved successfully.");
                                                        fetchUsers();
                                                    } catch (error) {
                                                        console.error("Error updating document:", error);
                                                        toast.error("An error occurred while approving the user.");
                                                        setLoading(false);
                                                    }
                                                }}
                                            >
                                                Approve
                                            </button>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        {user.employee ? (
                                            <button
                                                className="bg-red-500 text-white py-2 px-4 rounded"
                                                onClick={async () => {
                                                    setLoading(true);
                                                    try {
                                                        const userRef = doc(db, "users", id[index]);
                                                        await updateDoc(userRef, {
                                                            employee: false,
                                                        });
                                                        setLoading(false);
                                                        toast.success("Employee removed successfully.");
                                                        fetchUsers();
                                                    } catch (error) {
                                                        console.error("Error updating document:", error);
                                                        toast.error("An error occurred while removing employee status.");
                                                        setLoading(false);
                                                    }
                                                }}
                                            >
                                                Remove From Employee
                                            </button>
                                        ) : (
                                            <button
                                                className="bg-green-500 text-white py-2 px-4 rounded"
                                                onClick={async () => {
                                                    setLoading(true);
                                                    try {
                                                        const userRef = doc(db, "users", id[index]);
                                                        await updateDoc(userRef, {
                                                            employee: true,
                                                            commitmentHours: {
                                                                0: 0, 1: 0, 2: 0, 3: 0, 4: 0, 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0, 12: 0
                                                            },
                                                            commits: {},
                                                        });
                                                        setLoading(false);
                                                        toast.success("Employee added successfully.");
                                                        fetchUsers();
                                                    } catch (error) {
                                                        console.error("Error updating document:", error);
                                                        toast.error("An error occurred while adding employee status.");
                                                        setLoading(false);
                                                    }
                                                }}
                                            >
                                                Approve
                                            </button>
                                        )}
                                    </TableCell>
                                    <TableCell>
                                        <button
                                            className="bg-red-500 text-white py-2 px-4 rounded"
                                            onClick={() => {
                                                const confirmed = window.confirm("Are you sure you want to delete this user?");
                                                if (confirmed) {
                                                    const userRef = doc(db, "users", id[index]);
                                                    deleteDoc(userRef)
                                                        .then(() => {
                                                            toast.error("User deleted successfully.");
                                                            fetchUsers();
                                                        })
                                                        .catch((error) => {
                                                            console.error("Error deleting user:", error);
                                                            toast.error("An error occurred while deleting the user.");
                                                        });
                                                }
                                            }}
                                        >
                                            Delete
                                        </button>
                                    </TableCell>
                                </TableRow>
                            )
                        ))}
                    </TableBody>
                </Table>
            </div>
        </>
    );
};

export default User;
