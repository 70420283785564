import React, { useState, useEffect } from 'react';

const ErrorBoundary = ({ children }) => {
    const [hasError, setHasError] = useState(false);

    useEffect(() => {
        const handleError = (error, errorInfo) => {
            console.error("Error caught by ErrorBoundary:", error, errorInfo);
            // You can also log the error to an error tracking service
            // logErrorToMyService(error, errorInfo);
            setHasError(true);
        };

        window.addEventListener('error', handleError);
        return () => {
            window.removeEventListener('error', handleError);
            // window.location.reload();
        };
    }, []);

    if (hasError) {
        return <div>Something went wrong. Please refresh the page or try again later.</div>;
    }

    return <>{children}</>;
};

export default ErrorBoundary;
