import React, { useState, Fragment, useEffect } from 'react';
import { Menu, Transition } from '@headlessui/react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { auth, db, storage } from '../firebase'
import { Link, useHistory } from 'react-router-dom';
import Loader from '../Loader/Loader.jsx';
import Job from './Job.jsx';


const AllJob = () => {
    const [jobData, setJobData] = useState([]);
    const [loading, setLoading] = useState(true);
    useEffect(() => {
        const unsubscribe = onSnapshot(query(collection(db, 'Jobs')), (jobsSnapshot) => {
            const jobs = [];
            jobsSnapshot.forEach((jobDoc) => {
                const job = { ...jobDoc.data(), id: jobDoc.id };
                jobs.push(job);
            });

            setJobData(jobs);
            setLoading(false);
        });
        return () => {
            unsubscribe();
        };
    }, []);
    return (
        <div>
            {loading ? <Loader /> : (
                <>
                    <h1 style={{ textAlign: "center", marginBottom: "10px", marginTop: "20px" }}>All Jobs</h1>
                    <div className="flex flex-wrap">
                        {jobData.map((job) => (
                            <div key={job.id} className="w-full sm:w-1 md:w-1/2 lg:w-1/3 xl:w-1/4 p-4">
                                <Job
                                    jId={job.id}
                                    title={job.name}
                                    description={job.description}
                                    department={job.department}
                                    subtasks={job.subtasks}
                                    instructor={false}
                                />
                            </div>
                        ))}
                    </div>

                </>
            )}
        </div>
    );
};

export default AllJob;