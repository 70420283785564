import { useState, useEffect } from "react";
import { Form, Button } from 'react-bootstrap';
import Container from 'react-bootstrap/Container';
import { auth, db, storage } from '../firebase'
import { signInWithEmailAndPassword } from 'firebase/auth';
import { doc, getDocs, collection, query, where } from 'firebase/firestore';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link, useHistory } from 'react-router-dom';
import Loader from '../Loader/Loader.jsx';


const Login = () => {
    const [email, setEmail] = useState('');

    const [password, setPassword] = useState('');
    const [Uname, setUName] = useState('');
    const [approveStatus, setApproveStatus] = useState(false);
    const history = useHistory();
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        if (sessionStorage.getItem("user_email") != "" && !sessionStorage.getItem("user_email") == null) {
            history.push('/');
        }
    }, []);

    const submitHandler = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const userRef = collection(db, "users");
            const userDoc = query(userRef, where("Email", "==", email));
            const userData = await getDocs(userDoc);
            if (userData.empty) {
                toast.error("Please Register First");
                setLoading(false);
                return;
            }

            userData.forEach((doc) => {
                const userData = doc.data();
                const approved = userData.approved;
                const name = userData.Name;

                setApproveStatus(approved);
                setUName(userData.Name);
                if (approved) {
                    signIn();
                } else {
                    toast.error("Your account is not approved yet.");
                    setLoading(false);
                }
            });
        } catch (error) {
            setEmail('');
            setPassword('');
            toast.error("An error occurred while checking approval status.");
            setLoading(false);
        }
    };

    const signIn = async () => {
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            sessionStorage.setItem('user_id', user.uid);
            sessionStorage.setItem('user_email', user.email);
            toast.success("Successfully Logged In");
            setEmail('');
            setPassword('');
            setLoading(false);
            window.location.href = '/';
        } catch (error) {
            setEmail('');
            setPassword('');
            toast.error("Invalid Credentials");
            setLoading(false);
        }
    };

    const handleResetPassword = () => {
        history.push('/reset');
    };

    return (

        <Container>
            <ToastContainer />
            <div className="d-flex justify-content-center align-items-center" style={{ height: "600px" }}>
                <div>
                    <h1 style={{ marginTop: '20px', color: '#61bed6' }}>Community Login</h1>
                    <Form onSubmit={submitHandler}>


                        <Form.Group controlId="email" style={{ marginBottom: '20px' }} >
                            <Form.Control required
                                type="email"
                                placeholder="Enter Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </Form.Group>

                        <Form.Group controlId="password" style={{ marginBottom: '20px' }}>
                            <Form.Control required
                                type="password"
                                placeholder="Enter Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </Form.Group>
                        <p type="submit" onClick={handleResetPassword} variant='primary' style={{ color: "#61bFFF", marginBottom: '20px', textWeight: 'bold', textAlign: "left" }}>
                            <b>Forget Password?</b>
                        </p>
                        {loading ? <Loader /> : (
                            <Button type="submit" variant='primary' style={{ marginBottom: '20px', textWeight: 'bold' }}>
                                Login
                            </Button>)}
                        <p style={{ fontSize: "15px", fontWeight: "bold", textTransform: "uppercase", marginTop: '10px' }}>
                            Haven't joined our community yet?
                            <Link style={{ color: "#61bed6" }} to="/register"> Register now</Link>
                        </p>

                    </Form>
                </div>
            </div>
        </Container>
    );
};

export default Login;
