import React from 'react';
import Grid from '@mui/material/Grid';
import './product.css';
import consultationImg from '../../image/consultation.jpg'
import trainingImg from '../../image/training.jpg'
import manageServiceImg from '../../image/manageService.jpg'
import applicationImg from '../../image/application.jpg'
import vasImg from '../../image/vas.jpg'
import AnimatedCard from './AnimatedCard';

const cardData = [
  {
    name: 'Managed Service',
    image: manageServiceImg,
    content: 'Unlock seamless operations and peace of mind with our Managed Service solutions, expertly tailored to optimize your IT infrastructure.',
  },
  {
    name: 'Application & Automation',
    image: applicationImg,
    content: 'Experience increased efficiency and productivity through our cutting-edge Application and Automation services, streamlining your business processes.',
  },
  {
    name: 'Training Service',
    image: trainingImg,
    content: 'Empower your team with industry-leading knowledge and skills through our comprehensive Training programs, equipping them for success in the ever-evolving tech landscape.',
  },
  {
    name: 'VAS',
    image: vasImg,
    content: 'Enhance your offerings and customer experience with our Value Added Services (VAS), designed to differentiate your business and create lasting value for your clients.',
  },
  {
    name: 'Consultation',
    image: consultationImg,
    content: 'Leverage our expertise to make informed decisions and strategize for growth with our Consultation services, guiding you towards sustainable digital transformation.',
  },

];

const Product = () => {
  return (
    <div>
      <h3 className="service"><strong>Our Services</strong></h3>
      <Grid container spacing={2} justifyContent="center">
        {cardData.map((card, index) => (
          <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
            <AnimatedCard card={card} />
          </Grid>
        ))}
      </Grid>
    </div>
  );
};


export default Product;