import React, { useEffect, useState } from 'react';
import { collection, query, where, onSnapshot } from 'firebase/firestore';
import { auth, db, storage } from '../firebase.js'
import { Link, useHistory } from 'react-router-dom';
import Loader from '../Loader/Loader.jsx';
import Job from '../Job/Job.jsx';
import AddOutlinedIcon from '@mui/icons-material/AddOutlined';
import { doc, getDocs, updateDoc, deleteDoc } from 'firebase/firestore';



const InstructorJob = () => {
    const history = useHistory();
    const checkInstructor = async () => {
        const userRef = collection(db, "users");
        const userDoc = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
        const userData = await getDocs(userDoc);
        if (userData.empty) {
            history.push('/');
            return;
        }
        userData.forEach((doc) => {
            const userData = doc.data();
            if (!userData.isInstructor) {
                history.push('/');
            }
        });
    }
    useEffect(() => {
        checkInstructor();
    }, []);
    const [jobData, setJobData] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const unsubscribe = onSnapshot(query(collection(db, 'Jobs')), (jobsSnapshot) => {
            const jobs = [];

            jobsSnapshot.forEach((jobDoc) => {
                const job = { ...jobDoc.data(), id: jobDoc.id };
                jobs.push(job);
            });

            setJobData(jobs);
            setLoading(false);
        });
        return () => {
            unsubscribe();
        };
    }, []);
    return (
        <div>
            {loading ? <Loader /> : (
                <>
                    <div class="container px-6 m-auto">
                        <div class="grid grid-cols-1 gap-6 md:grid-cols-3 lg:grid-cols-3">
                            <div class="col-span-1 lg:col-span-2"><h1 style={{ marginTop: "10px" }}>All Jobs</h1></div>
                            <div class="col-span-1 lg:col-span-1 flex items-center justify-end">
                                <Link to="/instructor/addJob">
                                    <button class="inline-flex items-center justify-center h-12 gap-2 px-6 text-sm font-medium tracking-wide text-white transition duration-300 rounded focus-visible:outline-none whitespace-nowrap bg-cyan-500 hover:bg-cyan-600 focus:bg-cyan-700 disabled:cursor-not-allowed disabled:border-cyan-300 disabled:bg-cyan-300 disabled:shadow-none">
                                        <span class="order-2">Add Job</span>
                                        <span class="relative only:-mx-6">
                                            <AddOutlinedIcon />
                                        </span>
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </div>

                    <div class="container px-6 mx-auto">
                        <div class="grid grid-cols-1 gap-6 md:grid-cols-3 lg:grid-cols-3">
                            {jobData.map((job, index) => (
                                <div key={job.id} className="w-full p-4">
                                    <Job
                                        jId={job.id}
                                        title={job.name}
                                        description={job.description}
                                        department={job.department}
                                        subtasks={job.subtasks}
                                        instructor={true}
                                    />
                                </div>
                                // // Add a new row after every three jobs
                                // { index % 3 === 2 && <div className="hidden md:col-span-1 lg:col-span-1"></div>}
                            ))}
                        </div>
                    </div>
                </>

            )
            }

        </div >
    );
};

export default InstructorJob;
