import { useEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { auth, db, storage } from '../firebase.js'
import { doc, getDoc, getDocs, updateDoc, collection, deleteDoc, query, where } from 'firebase/firestore';
import Loader from '../Loader/Loader.jsx';
import { ToastContainer, toast } from 'react-toastify';

const Comminment = () => {
    const history = useHistory();
    const [month, setMonth] = useState();
    useEffect(() => {
        setLoading(true);
        if (!sessionStorage.getItem("user_email") != "" && sessionStorage.getItem("user_email") == null) {
            history.push('/');
        }
        setLoading(false);
        checkCommitmentHour();
    }, []);

    useEffect(() => {
        setLoading(true);
        checkCommitmentHour();
        setLoading(false);
    }, []);

    const [commitmentHour, setCommitmentHour] = useState(0);
    const [loading, setLoading] = useState(true);
    const monthNum = new Date().getMonth() + 1;
    const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August",
        "September", "October", "November", "December"];



    const checkCommitmentHour = async () => {
        setLoading(true);
        const userRef = collection(db, "users");
        const userDoc = query(userRef, where("Email", "==", sessionStorage.getItem("user_email")));
        const userData = await getDocs(userDoc);

        if (userData.empty) {
            toast.error("Invalid User");
            setLoading(false);
            return;
        }

        userData.forEach((doc) => {
            const userData = doc.data();
            setCommitmentHour(userData.commitmentHours[monthNum]);
        });

        setLoading(false);
    };


    const [commitInput, setCommitInput] = useState(0);
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const userId = sessionStorage.getItem("user_id");
        if (!userId) {
            console.error("User ID not found in sessionStorage");
            return;
        }

        const userRef = collection(db, "users");
        const userQuery = query(userRef, where("UID", "==", userId));
        const querySnapshot = await getDocs(userQuery);

        if (!querySnapshot.empty) {
            const userDoc = querySnapshot.docs[0].ref;
            const fieldToUpdate = `commitmentHours.${monthNum}`;

            await updateDoc(userDoc, {
                [fieldToUpdate]: commitInput

            });
            setLoading(false);
        } else {
            console.error("Document with UID does not exist:", userId);
            setLoading(false);
        }
        setLoading(false);
        checkCommitmentHour();
        window.location.reload();
    };


    return (
        <div style={{ marginTop: '10px' }}>
            {loading ? <Loader /> :
                (
                    <div className="mt-4 flex flex-col items-center">
                        {commitmentHour === 0 && (
                            <div className="flex flex-col items-center">
                                <form onSubmit={handleSubmit} className="bg-gray-100 p-4 rounded-md">
                                    <div className="flex items-center mb-2">
                                        <label className="mr-2">Enter Commitment (Hours) for {monthNames[monthNum - 1]}:</label>
                                        <input
                                            type="number"
                                            value={commitInput}
                                            onChange={(e) => setCommitInput(parseInt(e.target.value, 10))}
                                            className="border rounded p-2"
                                        />
                                    </div>
                                    <button type="submit" className="bg-blue-500 text-white p-2 rounded">Submit</button>
                                </form>
                            </div>
                        )}
                    </div>


                )
            }

        </div>
    )
}

export default Comminment
