import BarLoader from "react-spinners/SyncLoader";



function Loader() {

    return (
        <div className="sweet-loading" style={{ marginTop: '50px', marginBottom: "200px" }}>
            <BarLoader
                color={"#61bed6"}
                loading={true}
                // size={100}
                height={4}
                width={100}
                aria-label="Loading Spinner"
                data-testid="loader"

            />
        </div>
    );
}

export default Loader;