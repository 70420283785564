
import { initializeApp } from "firebase/app";
import {getStorage} from "firebase/storage"
import {getFirestore} from "firebase/firestore"

import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAUiIEZ5fdkt_mdgYsIfhunEhTBzVQRMLE",
  authDomain: "techavens-9e92a.firebaseapp.com",
  projectId: "techavens-9e92a",
  storageBucket: "techavens-9e92a.appspot.com",
  messagingSenderId: "610417724761",
  appId: "1:610417724761:web:76d135172eb149cd559048"
};

const app = initializeApp(firebaseConfig);

export const auth = getAuth(app);
export const storage = getStorage(app);
export const db = getFirestore(app);